import { BiShow, BiHide } from 'react-icons/bi';
interface PasswordIconProps {
  isHide: boolean;
  setIsHide: (value: boolean) => void;
}
const PasswordIcon = ({ isHide, setIsHide }: PasswordIconProps) => {
  return (
    <div>
      {isHide ? (
        <BiHide size={24} className='mr-4' onClick={() => setIsHide(false)} />
      ) : (
        <BiShow size={24} className='mr-4' onClick={() => setIsHide(true)} />
      )}
    </div>
  );
};

export default PasswordIcon;
