import * as React from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Oval } from 'react-loader-spinner';
import DocumentImage from '../../../components/DocumentImage/documentImage';
import PdfImage from '../../../components/DocumentImage/pdfImage';
import EditableInput from '../../../components/InputFields/EditableInput';
import EditableFiles from '../../../components/InputFields/EditFiles';
import { PDF_MESSAGE } from '../../../Constant/IRAPanelData';
import DeleteOtherDocument from './deleteOtherDocument';
interface OtherDocumentFileProps {
  type: string;
  name: string;
}
interface OtherDocumentProps {
  documentName: string;
  otherDocumentFile: OtherDocumentFileProps;
  otherDocumentFileURL: string;
}
const EditOtherDocument = ({
  otherDocument,
  handleInputChange,
  handleRemoveClick,
  handleAddClick,
  otherDocsHandler,
  convertBase64,
  userDetails,
  userId,
  loading,
}: any) => {
  const [currentIndex, setCurrentIndex] = React.useState(-1);
  const [fileNameError, setFileNameError] = React.useState<string>('');
  const handleDocumentNameChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
    if (e.target.value.length <= 30) {
      handleInputChange(e, i, 'name');
      setFileNameError('');
    } else {
      setFileNameError('Must be 30 characters or less*');
    }
  };
  const handleFocusChange = (e: any) => {
    const { value } = e.target;
    if (value.length <= 30) {
      setFileNameError('');
    }
  };
  const handleClick = () => {
    console.log('Clicked');
  };
  return (
    <div>
      <h2 className='text-lg font-medium mt-8 heading'>OTHER DOCUMENTS</h2>
      <div className='mt-6 px-6'>
        {userDetails?.other_documents.length > 0 && <DeleteOtherDocument userId={userId} userDetails={userDetails} />}
      </div>
      {otherDocument.map((document: OtherDocumentProps, i: number) => {
        return (
          <>
            <div className='alignmentAround mt-10 flex-wrap'>
              <EditableInput
                heading='Document Title:'
                type='text'
                value={document.documentName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleDocumentNameChange(e, i)}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleFocusChange(e)}
                className='inputStyle'
                name='documentName'
                error={fileNameError}
              />
              <div className='alignment flex-col'>
                {document.otherDocumentFileURL ? (
                  document.otherDocumentFile?.type === 'application/pdf' ? (
                    <div className='alignment'>
                      <div className='mt-8 imageContainer'>
                        <PdfImage title={document.otherDocumentFile?.name} />
                      </div>
                      <div className='alignment w-12'>
                        {otherDocument.length !== 1 && (
                          <button
                            className={`mr-2 px-5 py-2 w-28 text-sm font-extrabold  text-white rounded-xl alignment bg-darkblue hover:bg-lightButton active:bg-lightButton disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-lightButton `}
                            onClick={() => handleRemoveClick(i)}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          className={`px-5 py-2 w-28 text-sm font-extrabold  text-white rounded-xl alignment bg-darkblue hover:bg-lightButton active:bg-lightButton disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-lightButton `}
                          onClick={() => {
                            setCurrentIndex(i);
                            otherDocsHandler(i);
                          }}
                          disabled={!document.documentName || !document.otherDocumentFile || loading}
                        >
                          {loading && i === currentIndex ? <Oval color='white' height={16} width={16} /> : 'Upload'}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className='alignment'>
                        {document.otherDocumentFile ? (
                          <DocumentImage src={document.otherDocumentFileURL} />
                        ) : (
                          <EditableFiles
                            name='otherDocumentFile'
                            type='file'
                            value={document.otherDocumentFile}
                            onChange={(e: any) => {
                              {
                                convertBase64(e.target.files[0], 'otherDocumentFileURL', i), handleInputChange(e, i, 'file');
                              }
                            }}
                            placeholder={PDF_MESSAGE}
                            title={'Upload'}
                            parentStyle={'bg-grey h-52 p-5 rounded-lg lg:mr-8'}
                            uploadButtonStyle={'bg-pink'}
                            docTypes={'application/pdf'}
                            onclick={handleClick}
                          />
                        )}
                        <div className='alignment w-12'>
                          {otherDocument.length !== 1 && (
                            <button
                              className={`mr-2 px-5 py-2 w-28 text-sm font-extrabold  text-white rounded-xl alignment bg-darkblue hover:bg-lightButton active:bg-lightButton disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-lightButton `}
                              onClick={() => handleRemoveClick(i)}
                            >
                              Cancel
                            </button>
                          )}
                          <button
                            className={`px-5 py-2 w-28 text-sm font-extrabold  text-white rounded-xl alignment bg-darkblue hover:bg-lightButton active:bg-lightButton disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-lightButton `}
                            onClick={() => {
                              setCurrentIndex(i);
                              otherDocsHandler(i);
                            }}
                            disabled={!document.documentName || !document.otherDocumentFile || loading}
                          >
                            {loading && i === currentIndex ? <Oval color='white' height={16} width={16} /> : 'Upload'}
                          </button>
                        </div>
                      </div>
                    </>
                  )
                ) : null}
                {document.otherDocumentFile || !document.otherDocumentFileURL ? (
                  <EditableFiles
                    name='otherDocumentFile'
                    type='file'
                    value={document.otherDocumentFile}
                    onChange={(e: any) => {
                      {
                        convertBase64(e.target.files[0], 'otherDocumentFileURL', i), handleInputChange(e, i, 'file');
                      }
                    }}
                    placeholder={document.otherDocumentFileURL ? null : PDF_MESSAGE}
                    title={document.otherDocumentFileURL ? 'Change Document' : 'Upload'}
                    parentStyle={document.otherDocumentFileURL ? 'mr-8' : 'bg-grey h-52 p-5 rounded-lg lg:mr-16'}
                    uploadButtonStyle={document.otherDocumentFileURL ? 'bg-transparent text-darkblue w-40' : 'bg-pink'}
                    docTypes={'application/pdf'}
                    onclick={handleClick}
                  />
                ) : null}
              </div>
            </div>
            {otherDocument.length - 1 === i && (
              <button
                disabled={!document.documentName || !document.otherDocumentFile}
                className=' text-darkblue ml-3 mt-2 font-extrabold alignment disabled:cursor-not-allowed'
                onClick={handleAddClick}
              >
                <AiOutlinePlus className='mr-1' />
                Add More
              </button>
            )}
          </>
        );
      })}
    </div>
  );
};

export default EditOtherDocument;
