import React, { useState } from 'react';
import { IRA_ADMIN } from '../../../Api/panelApis';
import DocumentImage from '../../../components/DocumentImage/documentImage';
// import PdfImage from '../../../components/DocumentImage/pdfImage';
import PdfPreview from '../../../components/DocumentImage/pdfPreview';
import EditableSelectInput from '../../../components/InputFields/EditableSelectInput';
import EditableFiles from '../../../components/InputFields/EditFiles';
import { DOCUMENT_TYPE, PDF_MESSAGE } from '../../../Constant/IRAPanelData';
import { toastError, toastSuccess } from '../../../Utils/helper';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const EditKYCInfo = ({ editUserDetails, values, convertBase64, userId, investor_type }: any) => {
  const [frontImageURL, setFrontImageURL] = React.useState(values.frontImage);
  const [backImageURL, setBackImageURL] = React.useState(values.backImage);
  const [frontImage, setFrontImage] = React.useState('');
  const [backImage, setBackImage] = React.useState('');
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const submitKYC = async () => {
    setloading(true);
    const data = {
      documentType: values.documentType,
      frontImage,
      backImage,
    };
    try {
      const response = await IRA_ADMIN.KYCSubmit(data, userId, investor_type);
      if (response.success) {
        toastSuccess(response.message);
        setloading(false);
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
    } catch (error: any) {
      toastError(error.response.data.error);
    }
  };

  const handleClick = () => {
    console.log('Clicked');
  };

  // Helper function to extract file extension
  const getFileExtension = (fileName: string) => fileName?.split('.').pop()?.toLowerCase();

  return (
    <div>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-lg text-blue-600 font-medium mt-4 heading'>KYC INFO</h2>
      </div>
      <hr className='hrLine' />
      <div className='mt-6 px-6'>
        <div className='alignmentAround flex-wrap'>
          <EditableSelectInput
            captionKey='id'
            handler={editUserDetails}
            className='inputStyle'
            items={DOCUMENT_TYPE}
            selected={values.documentType}
            title={'Select Account Type'}
            heading={'Document Type:'}
            name='documentType'
          />
        </div>
      </div>
      <div className='mt-6 px-6'>
        <div className='alignmentAround flex-wrap'>
          <div className='flex-col alignment m-2'>
            {frontImageURL ? (
              <>
                <p>FRONT DOCUMENT</p>
                <p>{PDF_MESSAGE}</p>
                {getFileExtension(frontImageURL) === 'pdf' ? <PdfPreview url={frontImageURL} /> : <DocumentImage src={frontImageURL} />}
              </>
            ) : null}
            <EditableFiles
              type='file'
              imageUrl={frontImage}
              onChange={(e: any) => {
                convertBase64(e.target.files[0], setFrontImageURL);
                setFrontImage(e.target.files[0]);
              }}
              placeholder={frontImageURL || frontImage ? null : PDF_MESSAGE}
              title={frontImageURL || frontImage ? 'Change Document' : 'Upload'}
              parentStyle={frontImageURL || frontImage ? null : 'bg-grey h-52 p-5 rounded-lg'}
              uploadButtonStyle={frontImageURL || frontImage ? 'bg-transparent text-darkblue  w-40' : 'bg-pink'}
              onclick={handleClick}
            />
          </div>
          {values.documentType !== '1' && (
            <div className='flex-col alignment'>
              {backImageURL ? (
                <>
                  <p>BACK DOCUMENT</p>
                  <p>{PDF_MESSAGE}</p>
                  {getFileExtension(backImageURL) === 'pdf' ? <PdfPreview url={backImageURL} /> : <DocumentImage src={backImageURL} />}
                </>
              ) : null}
              <EditableFiles
                type='file'
                imageUrl={backImage}
                onChange={(e: any) => {
                  convertBase64(e.target.files[0], setBackImageURL);
                  setBackImage(e.target.files[0]);
                }}
                name='backImage'
                placeholder={backImage || backImageURL ? null : PDF_MESSAGE}
                title={backImage || backImageURL ? 'Change Document' : 'Upload'}
                parentStyle={backImage || backImageURL ? null : 'bg-grey h-52 p-5 rounded-lg'}
                uploadButtonStyle={backImage || backImageURL ? 'bg-transparent text-darkblue  w-40' : 'bg-pink'}
                onclick={handleClick}
              />
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-center'>
        <button onClick={submitKYC} className='loadingBtn' disabled={(!frontImage && !backImage) || loading}>
          {loading ? <ThreeDots height={20} width={30} color='white' ariaLabel='loading-indicator' /> : 'Update KYC'}
        </button>
      </div>
    </div>
  );
};

export default EditKYCInfo;
