import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import DwollaLogsTable from '../../IRAPanel/Table/DwollaLogsTable';

const DwollaLogs = ({ logs }: any) => {
  return (
    <CollapsibleContainer title='ACTIVITY LOGS'>
      <div className='mt-6 px-6'>
        <div>
          <DwollaLogsTable data={logs} />
        </div>
      </div>
    </CollapsibleContainer>
  );
};

export default DwollaLogs;
