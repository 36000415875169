import EditableInput from '../../../components/InputFields/EditableInput';
import EditableSelectInput from '../../../components/InputFields/EditableSelectInput';
import { CARL_TYPE, TRANSFER_ACCOUNT } from '../../../Constant/IRAPanelData';
import { EditUserDetailsProps } from '../../../Constant/Types/types';

interface EditAccountInfoProps {
  userApplication: EditUserDetailsProps;
  handleChange: {
    (e: React.ChangeEvent<HTMLInputElement>): void;
    <T = string | React.ChangeEvent<HTMLInputElement>>(field: T): T extends React.ChangeEvent<HTMLInputElement>
      ? void
      : (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  };
  errors: any;
}
const EditAccountInfo = ({ userApplication, handleChange, errors }: EditAccountInfoProps) => {
  return (
    <div>
      <div className='mx-4 pb-4 px-3'>
        <h2 className='text-lg text-blue-600 font-medium mt-8 heading'>ACCOUNT INFO</h2>
      </div>
      <hr className='hrLine' />
      <div className='mt-6 px-6'>
        <div className='grid  mx-6 grid-cols-3'>
          <EditableSelectInput
            captionKey='id'
            handler={handleChange}
            className='inputStyle'
            items={CARL_TYPE}
            selected={userApplication.ira_type}
            heading={'Investor type'}
            styles={''}
            name='ira_type'
          />
          <EditableInput
            heading='Initial Account contribution'
            type='number'
            value={userApplication.initial_ira_amount}
            onChange={handleChange}
            className='inputStyle'
            name='initial_ira_amount'
            error={errors.initial_ira_amount}
            icon={true}
          />
          <EditableSelectInput
            captionKey='id'
            handler={handleChange}
            className='inputStyle'
            items={TRANSFER_ACCOUNT}
            selected={userApplication.ira_account_type}
            heading={'Type of account'}
            styles={''}
            name='ira_account_type'
          />
        </div>
      </div>

      {userApplication.ira_account_type === '1' && (
        <div className='mt-6 px-6'>
          <div className='grid  mx-6 grid-cols-3'>
            <EditableInput
              heading='Custodian Account Name'
              type='text'
              value={userApplication.custodian_account_name}
              onChange={handleChange}
              className='inputStyle'
              name='custodian_account_name'
              error={errors.custodian_account_name}
            />
            <EditableInput
              heading='Custodian Account Number'
              type='text'
              value={userApplication.custodian_account_number}
              onChange={handleChange}
              className='inputStyle'
              name='custodian_account_number'
              error={errors.custodian_account_number}
            />
            <EditableInput
              heading='Custodian Amount'
              type='number'
              value={userApplication.custodian_amount}
              onChange={handleChange}
              className='inputStyle'
              name='custodian_amount'
              error={errors.custodian_amount}
              icon={true}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAccountInfo;
