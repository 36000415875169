import carlLogo from '../../assets/carl-logo.png';
import Button from '../../components/Buttons/button';
import { useNavigate, useParams } from 'react-router-dom';
import FormInput from '../../components/InputFields/FormInput';
import { useState } from 'react';
import { IRA_ADMIN } from '../../Api/panelApis';
import { toastError, toastSuccess } from '../../Utils/helper';
import PasswordIcon from '../../components/InputFields/passwordIcon';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [isHide, setIsHide] = useState<boolean>(true);

  const { id, token } = useParams();
  const handleSubmit = async () => {
    if (password.length > 5) {
      try {
        const response = await IRA_ADMIN.resetPassword(password, id, token);
        if (response.success) {
          toastSuccess(response.message);
          setTimeout(() => {
            navigate('/', { replace: true });
          }, 5000);
        }
      } catch (error: any) {
        toastError('The Reset link has expired');
      }
    } else {
      toastError('Password must be 6 digits or long.');
    }
  };
  return (
    <div className='alignment min-h-full px-4 py-12 sm:px-6 lg:px-8 mt-10'>
      <div className='w-full max-w-md space-y-8 p-4 rounded-lg shadow-2xl'>
        <div>
          <img className='w-auto h-24 mx-auto' src={carlLogo} alt='logo' />
          <h2 className='mt-6 text-2xl font-bold text-center  text-darkblue'>Reset Password</h2>
        </div>
        <div className='px-1'>
          <div className='w-full border-b-2 border-darkblue alignmentBetween'>
            <FormInput
              type={isHide ? 'password' : 'text'}
              placeholder='Enter New Password'
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              style='w-80 border-none'
            />
            <PasswordIcon isHide={isHide} setIsHide={setIsHide} />
          </div>
        </div>
        <div className='mb-5'>
          <Button
            text={'Reset Password'}
            style=' w-full px-4 py-2 text-md font-medium text-white mb-2 bg-darkblue border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={handleSubmit}
            disabled={!password}
          />
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;
