import { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import HeaderBar from '../../../../components/headerBar/headerBar';
import LeadsTable from '../../Table/leadsTable';
import Button from '../../../../components/Buttons/button';

const LeadsUsers = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [leadsUsers, setLeadsUsers] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const getAllLeadsUsers = useCallback(async () => {
    const {
      data,
      count: { total_users_count },
    } = await IRA_ADMIN.getLeadsUsers(page);
    setLeadsUsers(data);
    setIsLoading(false);
    setTotalPages(Math.ceil(total_users_count / 50));
  }, [page]);

  const filterData = leadsUsers?.sort((a: { id: number }, b: { id: number }) => b.id - a.id).filter((val: any) => val.phone_number);

  const leadsUsersData =
    searchData === ''
      ? filterData
      : filterData.filter((user: any) => JSON.stringify(user.id).includes(searchData) || user?.phone_number?.includes(searchData));

  useEffect(() => {
    const updateLeadsUsers = async () => {
      setIsLoading(true);
      await getAllLeadsUsers();
    };

    updateLeadsUsers();
  }, [page]);

  const previous = () => {
    setPage(current => current - 1);
  };

  const next = () => {
    setPage(current => current + 1);
  };

  return (
    <div className='panelTableContainer'>
      <HeaderBar title={`Leads Users`} />
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <div className='searchLeadUser'>
              <input type='text' placeholder='Search' className='searchInput' onChange={e => setSearchData(e.target.value)} />
              <MdSearch size={24} color='lightgrey' />
            </div>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <LeadsTable leadsUsers={leadsUsersData} />
          )}
          {!isLoading && (
            <div className='flex justify-between mx-4 my-6'>
              <Button text='Previous' onClick={previous} disabled={page <= 1} />
              <p className='font-bold'>
                Page: {page}/{totalPages}
              </p>
              <Button text='Next' onClick={next} disabled={page >= totalPages} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LeadsUsers;
