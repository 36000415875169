const PdfPreview = ({ url, fileName }: any) => {
  return (
    <>
      <a href={url} target='_blank' rel='noreferrer'>
        <div className='alignmentStartPreview block relative' style={{ height: '130px' }}>
          <img width='100' height='100' src='/Assets/pdflogo.png' alt='pdf-logo' className='imageContainerNew mx-auto' />
          <span className='flex items-center justify-center text-center font-semibold text-[#051D56]'>
            {fileName !== '' ? fileName : ''}
          </span>
        </div>
      </a>
    </>
  );
};

export default PdfPreview;
