import dayjs from 'dayjs';
import { useState } from 'react';
import { MdModeEditOutline } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { IRA_ADMIN } from '../../Api/panelApis';
import Button from '../../components/Buttons/button';
import Details from '../../components/Details/details';
import DocumentImage from '../../components/DocumentImage/documentImage';
import PdfPreview from '../../components/DocumentImage/pdfPreview';
import HeaderBar from '../../components/headerBar/headerBar';
import Modal from '../../components/Modal/modal';
import { Transactions_STATUS } from '../../Constant/IRAPanelData';
import { toastError, toastSuccess, tranactionType } from '../../Utils/helper';
import './TransactionDetail.css';

interface CustomizedState {
  data: any[];
  route: string;
  userId: number;
}

interface TransactionDetailsTypes {
  user_id: string;
  transaction_id: string;
  amount: number;
  start_date: string;
  end_date: string;
  transactions_type: number;
  transaction_status: number;
  transaction_document: string;
  transaction_details: string;
}

const TransactionDetail = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  // Fallback to localStorage if data is missing in location.state
  const state = (location.state as CustomizedState) || JSON.parse(localStorage.getItem('notOrifignaldata') || '{}');
  const { data, userId } = state;
  const showData = data[0].row.cells.map((item: any) => {
    return { [item.key]: item.value };
  });
  const transDetails: any = {};
  showData.map((item: any) => {
    return (transDetails[Object.keys(item)?.[0]] = Object.values(item)?.[0]);
  });

  const initialValues: TransactionDetailsTypes = {
    user_id: transDetails['User ID'],
    transaction_id: transDetails['Transaction ID'],
    amount: transDetails['Transaction Amount'],
    start_date: transDetails['Transaction Start Date'],
    end_date: transDetails['Transaction End Date'],
    transactions_type: transDetails['Transaction Type'],
    transaction_status: transDetails['Status'],
    transaction_document: transDetails['Document'],
    transaction_details: transDetails['Details'],
  };

  const fileName: any = initialValues?.transaction_document;
  const fileExtension = fileName?.split('.').pop();

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { user_id, transaction_id, transactions_type } = initialValues;
      const response = await IRA_ADMIN.approvedTransaction(
        Number(user_id || userId),
        Number(transaction_id),
        tranactionType(transactions_type).toLowerCase(),
      );
      if (response.success) {
        toastSuccess('Transaction Approved Successfully');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
      setLoading(false);
    } catch (error: any) {
      toastError('Something went wrong');
    }
  };
  const handleReject = async () => {
    setIsModalOpen(false);
    setLoading(true);
    try {
      const { user_id, transaction_id, transactions_type } = initialValues;
      const response = await IRA_ADMIN.rejectTransaction(
        Number(user_id || userId),
        Number(transaction_id),
        tranactionType(transactions_type).toLowerCase(),
      );
      if (response.success) {
        setLoading(true);
        toastSuccess('Transaction Rejected Successfully');
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      }
      setLoading(false);
    } catch (error: any) {
      toastError('Something went wrong');
    }
  };

  return (
    <div className='w-full max-w-8xl'>
      <HeaderBar title='View Transaction' />
      <div
        className='inline-block w-full max-w-8xl p-6 mb-8 overflow-hidden text-left align-middle transition-all transform bg-[#F2F4FF]'
        style={{ backgroundColor: '#f2f4ff' }}
      >
        <div
          className='w-full max-w-8xl p-6 my-4 overflow-auto text-left align-middle transition-all transform bg-white h-100 shadow-xl rounded-2x2'
          style={{ height: 'calc(100vh - 6rem)' }}
        >
          <div className='topContainer mx-4 pb-4 px-3'>
            <h2 className='text-lg text-blue-600 font-medium mt-8 heading'>TRANSACTION INFO</h2>
            {transDetails?.Status === Transactions_STATUS.STATUS_ACTIVE ? (
              <button
                className='px-4 py-2 alignment rounded-md text-white	font-normal bg-darkblue '
                onClick={() => {
                  navigate('/editTransaction', {
                    state: {
                      userId,
                      data,
                    },
                  });
                }}
              >
                <MdModeEditOutline size={18} className='mr-1' />
                Edit
              </button>
            ) : null}
          </div>
          <hr className='hrLine' />
          {loading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <div className='mt-6 px-6'>
              <div className='grid grid-cols-3'>
                <Details heading='Transaction ID' detail={initialValues.transaction_id} />
                <Details heading='Amount' detail={initialValues.amount} />
                <Details heading='Transaction Type' detail={tranactionType(initialValues.transactions_type)} />
                <Details heading='Start Date' detail={dayjs(initialValues.start_date).format('DD-MM-YYYY')} />
                <Details heading='End Date' detail={dayjs(initialValues.end_date).format('DD-MM-YYYY')} />
              </div>
              <Details heading='Details' detail={initialValues.transaction_details} />
              <div className='mx-4 pb-4 px-3'>
                <h2 className='text-sm text-blue-600 font-medium mt-4 heading'>TRANSACTION DOCUMENT</h2>
              </div>
              <hr className='hrLine' />
              <div className='alignment mt-5'>
                {initialValues?.transaction_document ? (
                  fileExtension === 'pdf' ? (
                    <PdfPreview url={initialValues?.transaction_document} />
                  ) : (
                    <DocumentImage src={initialValues?.transaction_document} />
                  )
                ) : (
                  <div className='mt-5'>
                    <p className='w-96 bg-grey h-52 p-5 rounded-lg alignment text-sm'>No Transaction document available</p>
                  </div>
                )}
              </div>
              {transDetails?.Status === Transactions_STATUS.STATUS_ACTIVE ? (
                <div className='mt-6 alignment'>
                  <Button text='APPROVE ' style='m-4 text-center rounded-md bg-darkblue' onClick={handleSubmit} />
                  <Button
                    text='REJECT'
                    style='m-4 text-center bg-red hover:bg-red rounded-md m-4 hover:opacity-70 focus:ring-red '
                    onClick={() => {
                      setIsModalOpen(true);
                    }}
                  />
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <Modal rejectTransaction={true} rejectHandler={handleReject} setisModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
    </div>
  );
};

export default TransactionDetail;
