import React from 'react';

const LargeValue: React.FC<{ value: string }> = ({ value }) => {
  const displayValue = value.length >= 10 ? `${value.slice(0, 10)}...` : value;

  return (
    <div className='w-32 mx-auto h-6 overflow-hidden'>
      <span title={value}>{displayValue}</span>
    </div>
  );
};

export default LargeValue;
