import './style.css';
import { BiCloudUpload } from 'react-icons/bi';
import React, { useState } from 'react';

interface DetailsProps {
  heading?: string;
  styles?: string;
  type: string;
  value?: any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  name?: string;
  imageUrl?: string;
  uploadBox?: string;
  placeholder?: string | null;
  uploadButtonStyle?: string;
  title?: string;
  parentStyle?: string | null;
  docTypes?: string;
  onclick: () => void;
}

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB in bytes

const EditableFiles: React.FC<DetailsProps> = ({
  heading,
  styles,
  onChange,
  uploadBox,
  placeholder,
  name,
  uploadButtonStyle,
  title,
  parentStyle,
  docTypes,
  onclick,
}) => {
  const [fileSizeError, setFileSizeError] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setFileSizeError('File size should not exceed 20 MB*');
        e.target.value = ''; // Clear the file input
        return;
      } else {
        setFileSizeError(null); // Clear any previous error
        onChange(e); // Call the parent onChange if the file is valid
      }
    }
  };

  return (
    <div className={`flex-col max-w-full alignment ${parentStyle}`}>
      <div className={`w-3/12 p-1 text-left ${styles} editableField`}>
        <p className='text-md flex justify-center headingText'>{heading}</p>
        <label className={`flex items-baseline justify-center ${uploadBox}`}>
          <div className={`alignment bg-darkGreen w-24 px-2 py-1 rounded-lg text-white ${uploadButtonStyle}`} onClick={onclick}>
            {title === 'Change Document' ? null : <BiCloudUpload size={20} className='mr-1' />}
            <span className='font-extrabold cursor-pointer'>{title}</span>
            <input
              type='file'
              onChange={handleFileChange}
              accept={`image/png, image/jpeg, ${docTypes}`}
              className='w-full text-sm mt-4'
              name={name}
            />
          </div>
        </label>
      </div>
      {fileSizeError && (
        <p className='text-sm text-red-500 text-center' style={{ color: 'rgb(239, 68, 68)', fontWeight: 'bold' }}>
          {fileSizeError}
        </p>
      )}
      <div className='text-sm text-center'>{placeholder}</div>
    </div>
  );
};

export default EditableFiles;
