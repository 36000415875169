type FormInputType = {
  type: 'text' | 'password' | 'number' | 'email';
  placeholder?: string;
  name?: string;
  value?: string;
  error?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  style?: string;
};
const FormInput: React.FC<FormInputType> = ({ name, value, placeholder, type, error, onChange, style }) => {
  return (
    <div className='my-1'>
      <input
        name={name}
        type={type}
        className={`focus:border-darkblue focus:outline-none border-b-2 border-darkblue px-3 py-2 text-darkblue placeholder:text-sm placeholder-gray-400 appearance-none  ${style}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete='none'
      />
      {error ? <p className='ml-1 text-xs text-red font-extrabold'>{error}</p> : null}
    </div>
  );
};
export default FormInput;
