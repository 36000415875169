import { ReactNode } from 'react';
import Collapsible from 'react-collapsible';
import { FiChevronDown } from 'react-icons/fi';
import HeadingTitle from '../Sections/HomeScreen/headingTitle';

interface Props {
  children: ReactNode;
  title: string;
  open?: boolean;
}

const CollapsibleContainer = ({ children, title, open }: Props) => {
  return (
    <Collapsible
      tabIndex={0}
      triggerStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px 10px 0px',
        backgroundColor: '#dcdee6',
        borderRadius: '6px',
        margin: '5px 0px',
      }}
      // @ts-ignore
      // eslint-disable-next-line react/jsx-key
      trigger={[<HeadingTitle title={title} />, <FiChevronDown size={30} />]}
      open={open}
    >
      {children}
    </Collapsible>
  );
};

export default CollapsibleContainer;
