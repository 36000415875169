import dayjs from 'dayjs';
import React from 'react';

const DateCell: React.FC<{ value: number }> = ({ value }) => {
  return (
    <div className=' min-w-max'>
      <span>{value ? dayjs(value).format('DD-MM-YYYY') : 0}</span>
    </div>
  );
};

export default DateCell;
