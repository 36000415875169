import Button from '../../../components/Buttons/button';

const TransactionInfo = ({ handleTransaction }: any) => {
  return (
    <div>
      <Button
        text='Add Transaction'
        style='bg-darkblue w-40 border-2 border-transparent mt-12 mx-auto rounded-md px-4'
        onClick={handleTransaction}
      />
    </div>
  );
};

export default TransactionInfo;
