import React, { useMemo } from 'react';
import { useSortBy, useTable } from 'react-table';
import { Link } from 'react-router-dom';
import { Listbox } from '@headlessui/react';
import { MdRemoveRedEye } from 'react-icons/md';
import { COLUMNS } from '../../../Constant/Column/column';
import '../IraPanel.css';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

const Table = ({ userData, pageNumber, usersPerPage }: any) => {
  const [checkButton, setCheckButton] = React.useState('');

  const caseInsensitiveSort = (rowA: any, rowB: any, columnId: string) => {
    const valueA = rowA.values[columnId];
    const valueB = rowB.values[columnId];

    // Handle undefined or null values
    if (valueA == null) return 1;
    if (valueB == null) return -1;

    // If both values are numbers, use a numeric comparison
    if (!isNaN(valueA) && !isNaN(valueB)) {
      return Number(valueA) - Number(valueB);
    }

    // Otherwise, perform case-insensitive string comparison
    return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
  };

  const columns = useMemo<any>(() => {
    return COLUMNS.map(column => {
      return {
        ...column,
        sortType: caseInsensitiveSort, // Use custom sorting function for all columns
      };
    });
  }, []);

  // Set up table instance
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: userData,
    },
    useSortBy,
  );

  // Slice sorted rows based on pagination values
  const pagesVisited = pageNumber * usersPerPage;
  const sortedRows = [...rows]; // Make a copy to preserve original row order
  const paginatedRows = sortedRows.slice(pagesVisited, pagesVisited + usersPerPage);
  const getRoute = window.location.href;

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, colKey) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} key={colKey}>
                <div className='flex items-center justify-center mx-auto'>
                  <span>{column.render('Header')}</span>
                  {!column.disableSortBy ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <FaSortDown className='ml-1' />
                      ) : (
                        <FaSortUp className='ml-1' />
                      )
                    ) : (
                      <FaSort className='ml-1' />
                    )
                  ) : null}
                </div>
              </th>
            ))}
            <th>View</th>
          </tr>
        ))}
      </thead>
      {userData.length ? (
        <tbody {...getTableBodyProps()}>
          {paginatedRows.map((row, index) => {
            prepareRow(row);
            const originalData = JSON.parse(JSON.stringify(row.original));
            originalData.perviousPath = getRoute;
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell: any, cellIndex) => (
                  <td {...cell.getCellProps()} key={cellIndex}>
                    {cell.render('Cell')}
                  </td>
                ))}
                <td className='text-center w-4'>
                  <Listbox value={checkButton} onChange={setCheckButton}>
                    <Listbox.Button className='text-center mx-auto'>
                      <Link to={`/UserDetailsApplications/${originalData.id}`} state={JSON.stringify(originalData)}>
                        <div className='mx-2'>
                          <MdRemoveRedEye />
                        </div>
                      </Link>
                    </Listbox.Button>
                  </Listbox>
                </td>
              </tr>
            );
          })}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <th className='relative left-1/3'>No matching records found</th>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default Table;
