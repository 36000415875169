const ViewRequest = ({ requestData }: any) => {
  const showData = requestData[0].row.cells.map((item: any) => {
    return { [item.key]: item.value };
  });
  const requestDetail: any = {};
  showData.map((item: any) => {
    return (requestDetail[Object.keys(item)?.[0]] = Object.values(item)?.[0]);
  });
  return (
    <div>
      <div className='mx-6'>
        <p className='supportRequest'>Email:</p>
        <h5 className='my-3 text-[#818181] text-base '>{requestDetail?.Email}</h5>
      </div>
      <div className='mx-6'>
        <p className='supportRequest'>Subject:</p>
        <h5 className='my-3 text-[#818181] text-base font-bold'>{requestDetail?.Subject}</h5>
      </div>
      <div className='mx-6'>
        <p className='supportRequest'>Message:</p>
        <h5 className='my-3 text-[#818181] text-base font-bold'>{requestDetail?.Message}</h5>
      </div>
    </div>
  );
};

export default ViewRequest;
