import { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import { IRA_ADMIN } from '../../Api/panelApis';
import { leadsDetailsColumn } from '../../Constant/Column/column';

const LeadUserModal = ({ leadUserData, coginitoData }: any) => {
  const [included, setIncluded] = useState([]);
  const [name, setName] = useState([]);
  const [address, setAddress] = useState([]);
  const [dob, setDob] = useState([]);
  const [ssn, setSsn] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    if (coginitoData) {
      setName(leadUserData.included?.filter((val: any) => val.type === 'name'));
      setAddress(leadUserData.included?.filter((val: any) => val.type === 'us_address'));
      setDob(leadUserData.included?.filter((val: any) => val.type === 'birth'));
      setSsn(leadUserData.included?.filter((val: any) => val.type === 'ssn'));
    } else {
      setName(included?.filter((val: any) => val.type === 'name'));
      setAddress(included?.filter((val: any) => val.type === 'us_address'));
      setDob(included?.filter((val: any) => val.type === 'birth'));
      setSsn(included?.filter((val: any) => val.type === 'ssn'));
    }
  }, [coginitoData, included, leadUserData.included]);

  const getLeadUser = async () => {
    const { value } = leadUserData[0];
    const response = await IRA_ADMIN.getLeadUserById(value);
    if (response.errors) {
      setError(response.errors[0].detail);
    }
    return response;
  };

  useEffect(() => {
    getLeadUser()
      .then(({ included }) => {
        setIncluded(included);
      })
      .catch(err => {
        console.log({ err });
        setError('Data not found');
      });
  }, []);

  function getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  const columns = useMemo<any>(() => leadsDetailsColumn, []);
  const columnHeaders = [{ name: '', country: '', date: '', address: '', ssn: '' }];
  const data = columnHeaders;

  const { getTableProps, headerGroups } = useTable({
    columns,
    data,
  });

  return (
    <div className='px-3 pb-8'>
      {leadUserData?.included?.length ? (
        <table {...getTableProps()} className='table-auto px-2 pb-5'>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, colKey) => (
                  <th className='text-left p-2' {...column.getHeaderProps()} key={colKey}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {name?.map((eachName: any, index) => {
                  const {
                    attributes: { first },
                  } = eachName;
                  return (
                    <p className='text-left' key={index}>
                      {first || ''}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {name?.map((eachName: any, index) => {
                  const {
                    attributes: { last },
                  } = eachName;
                  return (
                    <p className='text-left' key={index}>
                      {last || ''}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {address?.map((eachAdd: any, index) => {
                  const {
                    attributes: { city, street, country_code, subdivision },
                  } = eachAdd;
                  return (
                    <p className='text-left' key={index}>
                      {`${street ? street + ',' : ''} ${subdivision ? subdivision + ',' : ''} ${city ? city + ',' : ''} ${
                        country_code ? country_code + ',' : ''
                      }`}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {dob?.map((eachDate: any, index) => {
                  const {
                    attributes: { day, year, month },
                  } = eachDate;
                  return (
                    <p className='text-left ' key={index}>
                      {`${day || ''} ${getMonthName(month) || ''} ${year || ''}`}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {ssn?.map((eachSsn: any, index) => {
                  const {
                    attributes: { number },
                  } = eachSsn;
                  return (
                    <p className='text-left' key={index}>
                      {number || ''}
                    </p>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
      ) : included ? (
        <table {...getTableProps()} className='table-auto px-2 pb-5'>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, colKey) => (
                  <th className='text-left p-2' {...column.getHeaderProps()} key={colKey}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            <tr>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {name?.map((eachName: any, index) => {
                  const {
                    attributes: { first },
                  } = eachName;
                  return (
                    <p className='text-left' key={index}>
                      {first || ''}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {name?.map((eachName: any, index) => {
                  const {
                    attributes: { last },
                  } = eachName;
                  return (
                    <p className='text-left' key={index}>
                      {last || ''}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {address?.map((eachAdd: any, index) => {
                  const {
                    attributes: { city, street, country_code, subdivision },
                  } = eachAdd;
                  return (
                    <p className='text-left' key={index}>
                      {`${street ? street + ',' : ''} ${subdivision ? subdivision + ',' : ''} ${city ? city + ',' : ''} ${
                        country_code ? country_code + ',' : ''
                      }`}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {dob?.map((eachDate: any, index) => {
                  const {
                    attributes: { day, year, month },
                  } = eachDate;
                  return (
                    <p className='text-left ' key={index}>
                      {`${day || ''} ${getMonthName(month) || ''} ${year || ''}`}
                    </p>
                  );
                })}
              </td>
              <td className='align-top p-2' style={{ width: 'unset' }}>
                {ssn?.map((eachSsn: any, index) => {
                  const {
                    attributes: { number },
                  } = eachSsn;
                  return (
                    <p className='text-left' key={index}>
                      {number || ''}
                    </p>
                  );
                })}
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <p className='text-center pb-8'>{error}</p>
      )}
    </div>
  );
};

export default LeadUserModal;
