import { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import Button from '../../../../components/Buttons/button';
import HeaderBar from '../../../../components/headerBar/headerBar';
import TransactionTable from '../../Table/TransactionTable';

const Transactions = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState(1);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [visited, setVisited] = useState<number[]>([]);
  const [triverse, setTriverse] = useState(1);

  const route = window?.location?.href?.split('/').at(-1);

  const getAllTransactions = useCallback(
    async (signal, route) => {
      if (page <= triverse && visited[page - 1]) {
        return transactionsList;
      } else {
        setIsLoading(true);
        const response = await IRA_ADMIN.entityFundTransactionList(signal, route, page);
        setTransactionsList(transactionsList.concat(response?.data));
        setVisited([...visited, transactionsList.concat(response?.data)?.length]);
        setTotalTransactions(response?.count?.total_transaction_count);
        setIsLoading(false);
      }
    },
    [page, triverse],
  );

  const totalPage = Math.ceil(totalTransactions / 50);

  useEffect(() => {
    setPage(1);
    setTriverse(1);
    setVisited([]);
    setTransactionsList([]);
  }, [route]);

  const TransactionsData =
    searchData === ''
      ? transactionsList.slice((page - 1) * 50, page < triverse ? visited[page - 1] : transactionsList?.length)
      : transactionsList?.slice((page - 1) * 50, page < triverse ? visited[page - 1] : transactionsList?.length).filter((user: any) => {
          return (
            JSON.stringify(user.id).includes(searchData) ||
            JSON.stringify(user['transaction id']).includes(searchData) ||
            user?.phone_number?.includes(searchData) ||
            user?.first_name.toLowerCase().includes(searchData.toLowerCase()) ||
            user?.last_name.toLowerCase().includes(searchData.toLowerCase())
          );
        });

  useEffect(() => {
    const controller = new AbortController();
    getAllTransactions(controller.signal, route);
    return () => {
      controller.abort();
    };
  }, [getAllTransactions, route]);

  const next = () => {
    setPage(current => current + 1);
    if (page + 1 > triverse) {
      setTriverse(current => current + 1);
    }
  };

  const previous = () => {
    setPage(current => current - 1);
  };

  return (
    <div className='panelTableContainer'>
      <HeaderBar title={`Funds Transactions`} />
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <div className='searchLeadUser'>
              <input type='text' placeholder='Search' className='searchInput' onChange={e => setSearchData(e.target.value)} />
              <MdSearch size={24} color='lightgrey' />
            </div>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <TransactionTable transactionsList={TransactionsData} />
          )}
          {!isLoading && (
            <div className='flex justify-between mx-4 my-6'>
              <Button text='Previous' onClick={previous} disabled={page <= 1} />
              <p className='font-bold'>
                Page: {page}/{totalPage}
              </p>
              <Button text='Next' onClick={next} disabled={page >= totalPage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
