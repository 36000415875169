import HeaderCards from '../../HeaderCards/headerCards';
import { MdOfflinePin, MdPending, MdIncompleteCircle, MdReplayCircleFilled, MdCancel } from 'react-icons/md';

interface HeaderCardProps {
  cardsData: any;
  isLoading: boolean;
  setApplicationStatus?: any;
}

const HeaderCard: React.FC<HeaderCardProps> = ({ cardsData, isLoading }) => {
  return (
    <>
      <div className='alignmentAround bg-[#F2F4FF] flex-wrap'>
        <HeaderCards
          title={'Approved'}
          value={isLoading ? 0 : cardsData.approved}
          iconBackground='rgb(211, 245, 220)'
          icon={<MdOfflinePin color='rgb(23, 151, 62)' size={24} />}
        />
        <HeaderCards
          title={'Pending'}
          value={isLoading ? 0 : cardsData.inProcess}
          iconBackground='rgb(205, 229, 253)'
          icon={<MdPending color='rgb(35, 116, 200)' size={24} />}
        />
        <HeaderCards
          title={'Incomplete'}
          value={isLoading ? 0 : cardsData.incompleteUsers + cardsData.notStarted}
          iconBackground='rgb(233, 216, 251)'
          icon={<MdIncompleteCircle color='rgb(139, 44, 211) ' size={24} />}
        />
        <HeaderCards
          title={'Need Resubmission'}
          value={isLoading ? 0 : cardsData.resubmit}
          iconBackground='rgb(255, 227, 191)'
          icon={<MdReplayCircleFilled color='rgb(240, 135, 28)' size={24} />}
        />
        <HeaderCards
          title={'Rejected'}
          value={isLoading ? 0 : cardsData.decline}
          iconBackground='rgb(253, 213, 213) '
          icon={<MdCancel color='rgb(238, 67, 40)' size={24} />}
        />
      </div>
    </>
  );
};

export default HeaderCard;
