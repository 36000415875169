import React, { useCallback } from 'react';
import Table from '../../Table/table';
import './iraPanel.css';
import { APPLICATION_STATUS, STATUS } from '../../../../Constant/IRAPanelData';
import Dropdown from '../../../../components/dropdown/dropdown';
import ReactPaginate from 'react-paginate';
import HeaderBar from '../../../../components/headerBar/headerBar';
import HeaderCard from '../../../../components/Sections/HomeScreen/headerCard';
import { MdSearch } from 'react-icons/md';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import { ThreeDots } from 'react-loader-spinner';
import { AppContext } from '../../../../Context/Context';

interface UsersProps {
  application_status: string;
  country: string;
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  phone_number: string;
  started_at: string;
}

const IRAPanel = () => {
  const [applicationStatus, setApplicationStatus] = React.useState('ALL');
  const { allUsersdata, setAllUsersdata } = React.useContext(AppContext);
  const [users, setUsers] = React.useState(allUsersdata);
  const [pageNumber, setPageNumber] = React.useState(0);
  const [searchData, setSearchData] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(true);
  const filterData = users
    ?.sort((a: { id: number }, b: { id: number }) => b.id - a.id)
    .filter((user: UsersProps) => user.first_name !== '');
  const applications =
    applicationStatus === 'ALL'
      ? filterData
      : filterData.filter((user: UsersProps) => applicationStatus && applicationStatus === user.application_status);
  const search =
    searchData === ''
      ? applications
      : applications.filter((user: UsersProps) => user.first_name.toLowerCase().includes(searchData.toLowerCase()));
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers = search.slice(pagesVisited, pagesVisited + usersPerPage);
  const pageCount = Math.ceil(search.length / usersPerPage);
  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };
  const searchByName =
    searchData === ''
      ? {}
      : applications.filter(
          (user: UsersProps) =>
            user.first_name.toLowerCase().includes(searchData.toLowerCase()) ||
            user.email.toLowerCase().includes(searchData.toLowerCase()) ||
            user.phone_number.includes(searchData) ||
            String(user.id).includes(searchData.toLowerCase()),
        );
  const getAllUser = useCallback(
    async signal => {
      const response = await IRA_ADMIN.getAllIraUsers(signal);
      setIsLoading(false);
      setAllUsersdata(response);
      setUsers(response);
    },
    [setAllUsersdata],
  );

  let incompleteUsers = 0;
  let inProcess = 0;
  let approved = 0;
  let resubmit = 0;
  let notStarted = 0;
  let decline = 0;

  filterData?.filter((user: UsersProps) => {
    if (user.application_status === STATUS.ONBOARDING_PENDING) {
      return incompleteUsers++;
    } else if (user.application_status === STATUS.PENDING) return inProcess++;
    else if (user.application_status === STATUS.APPROVED) return approved++;
    else if (user.application_status === STATUS.RESUBMIT) return resubmit++;
    else if (user.application_status === STATUS.DECLINED) return decline++;
    else return notStarted++;
  });
  const cardsData = { incompleteUsers, inProcess, approved, resubmit, notStarted, decline };

  React.useMemo(() => {
    setPageNumber(0);
  }, [applicationStatus]);

  React.useEffect(() => {
    const controller = new AbortController();
    getAllUser(controller.signal);
    window.scrollTo({ top: 0, left: 0 });
    return () => {
      controller.abort();
    };
  }, [allUsersdata.length, getAllUser]);

  return (
    <div className='panelTableContainer'>
      <HeaderBar title='Onboarding Application' totalApplication={filterData?.length} />
      <HeaderCard isLoading={isLoading} cardsData={cardsData} />
      <div className='panelTable'>
        <div className='dropDownSection'>
          <div className='panelHeadingContainer'>
            <div className='searchInputContainer w-1/3'>
              <input type='text' placeholder='Search Application' className='searchInput' onChange={e => setSearchData(e.target.value)} />
              <MdSearch size={24} color='lightgrey' />
            </div>
            <div>
              <Dropdown
                items={APPLICATION_STATUS}
                selected={applicationStatus}
                captionKey='value'
                className='dropdownInput'
                handler={setApplicationStatus}
              />
            </div>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <Table userData={searchByName.length ? searchByName : displayUsers} />
          )}
          {displayUsers.length ? (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
              forcePage={pageNumber}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default IRAPanel;
