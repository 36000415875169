import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import { AppContext } from '../Context/Context';
import ProtectedRoutes from './ProtectedRoutes';
import PublicRoutes from './PublicRoutes';

const MainRoutes = () => {
  const { token } = React.useContext(AppContext);

  return (
    <BrowserRouter>
      <Routes>
        {token ? (
          // Protected routes for authenticated users
          <Route path='/*' element={<ProtectedRoutes />} />
        ) : (
          // Public routes for unauthenticated users
          <Route path='/*' element={<PublicRoutes />} />
        )}
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;
