import React from 'react';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import Details from '../../../components/Details/details';
import { ACCREDITATION_STATUS_SELECT, Investor_Type, isIRA } from '../../../Constant/IRAPanelData';
import { UserDetailsProps } from '../../../Constant/Types/types';
import { isPaperApp } from '../../../Utils/helper';
import { AppContext } from '../../../Context/Context';
interface PersonalInfoProps {
  userDetails: UserDetailsProps;
}

const PersonalInfo = ({ userDetails }: PersonalInfoProps) => {
  const { infoUpdated } = React.useContext(AppContext);

  return (
    <CollapsibleContainer title='PERSONAL INFO' open={true}>
      <div className='mt-6 px-6'>
        <div className='grid grid-cols-3'>
          <Details heading='First Name' detail={userDetails?.personalinfodata?.first_name} />
          <Details heading='Last Name' detail={userDetails?.personalinfodata?.last_name} />
          {window.location.href.includes('UserDetailsInvestors') ? (
            <Details heading='CARL Investor' detail={!infoUpdated ? 'Yes' : 'No'} />
          ) : null}
          <Details heading='Investor Type' detail={userDetails?.personalinfodata?.investor_type} />
          <Details heading='Mobile Number' detail={userDetails?.personalinfodata?.phone_number} />
          <Details heading='Email' detail={userDetails?.personalinfodata?.email} />
          {!isPaperApp.includes(userDetails?.personalinfodata?.investor_type) ? (
            <Details heading='Date Of Birth' detail={userDetails?.personalinfodata?.dob} />
          ) : null}
          {userDetails?.personalinfodata?.investor_type === Investor_Type.US_ENTITY ? (
            <>
              <Details heading='Company EIN' detail={userDetails?.personalinfodata?.ein_number} />
              <Details heading='SSN' detail={userDetails?.personalinfodata?.ssn} />
            </>
          ) : userDetails?.accountinfo || userDetails?.personalinfodata?.investor_type === Investor_Type.US_USER ? (
            <Details heading='SSN' detail={userDetails?.personalinfodata?.ssn} />
          ) : null}
          {userDetails?.personalinfodata.investor_type === Investor_Type.US_ENTITY ||
          userDetails?.personalinfodata.investor_type === Investor_Type.NON_US_ENTITY ? (
            <Details heading='Company Name' detail={userDetails?.personalinfodata?.company_name} />
          ) : null}
          {isIRA(userDetails?.personalinfodata.investor_type) ? (
            <>
              <Details heading='Marital Status' detail={userDetails?.personalinfodata?.marrital_status ? 'Married' : 'Unmarried'} />
              <Details
                heading='accreditation status'
                detail={ACCREDITATION_STATUS_SELECT[userDetails?.personalinfodata?.accreditation_type]}
                tooltipId='accreditation'
              />
            </>
          ) : null}
        </div>
      </div>
    </CollapsibleContainer>
  );
};

export default PersonalInfo;
