import Button from '../Buttons/button';
import FormInput from '../InputFields/FormInput';

const InformUserModal = ({ name, email, informUser, emailContent }: any) => {
  return (
    <div className='mx-4'>
      <div>
        <p className='font-bold text-lightGrey font-sm outline-none'>Name:</p>
        <FormInput
          type={'text'}
          style='rounded-lg border-2 border-lightGrey outline-darkblue	 text-darkGrey font-bold w-full'
          value={name}
        />
      </div>
      <div>
        <p className='font-bold text-lightGrey font-sm outline-none'>Email:</p>
        <FormInput
          type={'text'}
          style='rounded-lg border-2 border-lightGrey text-darkGrey outline-darkblue font-bold w-full'
          value={email}
        />
      </div>
      <div>
        <p className='font-bold text-lightGrey font-sm outline-none'>Message:</p>
        <div className='rounded-lg border-2 border-lightGrey text-darkGrey font-bold p-4 h-48 overflow-scroll'>{emailContent}</div>
      </div>
      <div className='alignment w-1/3 text-center rounded-lg my-4 m-auto'>
        <Button text='Send It!' style='text-white px-0' onClick={informUser} />
      </div>
    </div>
  );
};

export default InformUserModal;
