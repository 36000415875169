interface HeadingTitleProps {
  title: string;
}

const HeadingTitle: React.FC<HeadingTitleProps> = ({ title }) => {
  return (
    <>
      <div className='mx-4 py-2 px-2'>
        <h2 className='text-xl text-blue-600 font-medium heading'>{title}</h2>
      </div>
      <hr className='hrLine' />
    </>
  );
};

export default HeadingTitle;
