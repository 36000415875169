// sortTypes.ts
import { Row } from 'react-table';

export const dateSort = <T extends object>(rowA: Row<T>, rowB: Row<T>, columnId: string): number => {
  const a = new Date(rowA.values[columnId] as string);
  const b = new Date(rowB.values[columnId] as string);

  if (isNaN(a.getTime())) {
    return 1; // If a is not a valid date, push it to the end
  }
  if (isNaN(b.getTime())) {
    return -1; // If b is not a valid date, push it to the end
  }

  return a.getTime() - b.getTime(); // Default sort (ascending)
};
