import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import Details from '../../../components/Details/details';
// import DocumentImage from '../../../components/DocumentImage/documentImage';
// import PdfPreview from '../../../components/DocumentImage/pdfPreview';
import { Investor_Type } from '../../../Constant/IRAPanelData';
import { UserDetailsProps } from '../../../Constant/Types/types';

interface AddressInfoProps {
  userDetails: UserDetailsProps;
}

const AddressInfo = ({ userDetails }: AddressInfoProps) => {
  // const fileName: any = userDetails?.addressinfodata.docFile;
  // const fileExtension = fileName?.split('.').pop();

  return (
    <CollapsibleContainer title='ADDRESS INFO'>
      <div className='mt-6 px-6'>
        <div className='grid grid-cols-3'>
          <Details heading='Address 1' detail={userDetails?.addressinfodata.address1} />
          <Details heading='Address 2' detail={userDetails?.addressinfodata.address2 || 'none'} />
          <Details heading='City' detail={userDetails?.addressinfodata.city} />
          <Details heading='Zip Code' detail={userDetails?.addressinfodata.zipCode} />
          <Details
            heading={
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_ENTITY ||
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_USER
                ? 'Country'
                : 'State'
            }
            detail={
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_ENTITY ||
              userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_USER
                ? userDetails?.addressinfodata.country
                : userDetails?.addressinfodata.state
            }
          />
          {userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_ENTITY ||
          userDetails?.personalinfodata?.investor_type === Investor_Type.NON_US_USER ? null : (
            <Details heading='Country' detail={'United State'} />
          )}
          {userDetails?.personalinfodata?.investor_type === Investor_Type.US_ENTITY && (
            <Details heading='Entity Address Information' detail={userDetails?.addressinfodata.address1 || 'none'} />
          )}
        </div>
      </div>
      <div className='mt-6 px-6'>
        <div className='alignmentBetween mx-6  flex-wrap'></div>
        <div className='alignmentBetween mx-6  flex-wrap'></div>
      </div>
      {/* <div className='mx-4 pb-4 px-3'>
        <h2 className='text-sm text-blue-600 font-medium mt-4 heading'>ADDRESS VERIFICATION DOCUMENT</h2>
      </div>
      <hr className='hrLine' />
      <div className='mt-6 px-6'>
        <Details heading='Document Type' detail={STATEMENT_TYPE[Number(userDetails?.addressinfodata.optionType)]} />
        <div className='alignment m-5'>
          {userDetails?.addressinfodata.docFile ? (
            fileExtension === 'pdf' ? (
              <PdfPreview url={userDetails?.addressinfodata.docFile} />
            ) : (
              <DocumentImage src={userDetails?.addressinfodata.docFile} />
            )
          ) : (
            <div>
              <p className='text-center m-2'>DOCUMENT IMAGE</p>
              <p className='w-96 bg-grey h-52 p-5 rounded-lg alignment text-sm'>No address verification document available</p>
            </div>
          )}
        </div>
      </div> */}
    </CollapsibleContainer>
  );
};

export default AddressInfo;
