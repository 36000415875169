import Dropdown from '../dropdown/dropdown';
import './style.css';

interface SelectDropdown {
  captionKey: string;
  className?: string;
  items: any;
  handler: (value: string) => void;
  disable?: boolean;
  selected: any;
  title?: string;
  setAs?: string;
  heading: string;
  styles?: string;
  name?: string;
}
const EditableSelectInput: React.FC<SelectDropdown> = ({
  captionKey,
  handler,
  className,
  items,
  selected,
  title,
  heading,
  styles,
  name,
}) => {
  return (
    <div className={`w-3/12 p-1 text-left ${styles} editableFieldSelect`}>
      <p className='text-md headingText'>{heading}</p>
      <Dropdown
        items={items}
        selected={selected}
        captionKey={captionKey}
        className={` w-3/12 mt-1 text-darkblue border-b-pink font-extrabold cursor-pointer ${className} editableFieldSelect`}
        handler={handler}
        title={title}
        name={name}
      />
    </div>
  );
};

export default EditableSelectInput;
