import { toast, Flip } from 'react-toastify';
import { Investor_Type } from '../Constant/IRAPanelData';

export const toastSuccess = (title: string) => {
  toast.success(title, {
    autoClose: 2000,
    transition: Flip,
  });
};

export const toastError = (title: string) => {
  toast.error(title, {
    autoClose: 2000,
    transition: Flip,
  });
};
export const toastLoading = (title: string) => {
  toast.loading(title, {
    autoClose: 2000,
    transition: Flip,
  });
};

export const SearchText = (text: string) => {
  switch (text) {
    case 'user_id':
      return 'User ID';
    case 'first_name':
      return 'Name';
    case 'email':
      return 'Email';
    case 'phone_number':
      return 'Phone Number';
  }
};

export const isPaperApp = [Investor_Type.US_ENTITY, Investor_Type.NON_US_ENTITY, Investor_Type.NON_US_USER];

export const validateEmail = (email: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const withdraw = ['nonUSfundswithdraw', 'entityfundswithdraw', 'nonUSentityfundswithdraw'];

export const tranactionType = (value: number) => {
  switch (value) {
    case 1:
      return 'Deposit';
    default:
      return 'Withdraw';
  }
};
