interface ViewCertificateProps {
  certificateUrl: string | undefined;
}

const ViewCertificate = ({ certificateUrl }: ViewCertificateProps) => {
  if (!certificateUrl) {
    return <div className='flex justify-center m-4'>No certificate available</div>;
  }

  const isPdf = certificateUrl.endsWith('.pdf');

  return (
    <div className='flex justify-center flex-wrap m-4'>
      {isPdf ? (
        <iframe
          src={certificateUrl + '#toolbar=0'} // Hides the toolbar with download option in some browsers
          title='Certificate'
          className='w-full h-96'
          style={{ border: 'none' }} // Removes the border
        />
      ) : (
        <img src={certificateUrl} alt='Certificate' className='max-w-full h-auto' />
      )}
    </div>
  );
};

export default ViewCertificate;
