interface PdfImageProps {
  title: string;
}

const PdfImage: React.FC<PdfImageProps> = ({ title }) => {
  return (
    <div className='alignment flex-col'>
      <img width='100' height='100' src='/Assets/pdflogo.png' alt='pdf-logo' />
      <h2 className='font-bold text-darkblue mt-2'>{title}</h2>
    </div>
  );
};

export default PdfImage;
