import { MdCancel, MdIncompleteCircle, MdOfflinePin, MdPending, MdReplayCircleFilled } from 'react-icons/md';
import { STATUS } from '../../Constant/IRAPanelData';
import StatusPill from '../StatusPill/statusPill';

const StatusButton = ({ status }: any) => {
  const checkStatus = (applicationStatus: string) => {
    switch (applicationStatus) {
      case STATUS.APPROVED:
        return (
          <StatusPill
            icon={<MdOfflinePin color='text-darkGreen' size={24} />}
            textColor='text-darkGreen'
            backgroundcolor='bg-lightGreen'
            status={'Approved'}
          />
        );
      case STATUS.PENDING:
      case STATUS.BANK_APPROVED:
      case STATUS.BANK_PENDING:
        return (
          <StatusPill
            icon={<MdPending color='text-pendingDarkBtn' size={24} />}
            textColor='text-pendingDarkBtn'
            backgroundcolor='bg-pendingLightBtn'
            status={'Pending'}
          />
        );
      case STATUS.ONBOARDING_PENDING:
        return (
          <StatusPill
            icon={<MdIncompleteCircle color='text-lightBlue' size={24} />}
            textColor='text-lightBlue'
            backgroundcolor='bg-darkBlue'
            status={'Incomplete'}
          />
        );
      case STATUS.RESUBMIT:
        return (
          <StatusPill
            icon={<MdReplayCircleFilled color='text-darkOrange' size={24} />}
            textColor='text-darkOrange'
            backgroundcolor='bg-lightOrange'
            status={'Resubmit'}
          />
        );
      // case STATUS.BANK_PENDING:
      //   return (
      //     <StatusPill
      //       icon={<MdReplayCircleFilled color='text-darkOrange' size={24} />}
      //       textColor='text-[#c922c4]'
      //       backgroundcolor='bg-[#f7c3f6]'
      //       status={'Dwolla Pending'}
      //     />
      //   );
      case STATUS.DECLINED:
        return (
          <StatusPill
            icon={<MdCancel color='text-darkRed' size={24} />}
            textColor='text-darkRed'
            backgroundcolor='bg-lightRed'
            status={'Rejected'}
          />
        );
    }
  };

  return <div>{checkStatus(status)}</div>;
};

export default StatusButton;
