import { Form, Formik } from 'formik';
import { useState } from 'react';
import { IRA_ADMIN } from '../../Api/panelApis';
import { toastError, toastSuccess } from '../../Utils/helper';
import { craeteAdminSchema } from '../../Validation/validationScema';
import Button from '../Buttons/button';
import FormInput from '../InputFields/FormInput';

const CreateAdmin = ({ setIsOpen, setisModalOpen }: any) => {
  const [adminEmail, setAdminEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+');
  const [adminPassword, setAdminPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const createAdminHandler = async (updatedValues: any) => {
    const { first_name, last_name, phone_number, email, adminPassword } = updatedValues;
    const data = {
      adminEmail: email,
      phoneNumber: phone_number,
      adminPassword,
      firstName: first_name,
      lastName: last_name,
    };
    try {
      const response = await IRA_ADMIN.createAdmin(data);
      if (response.success) {
        toastSuccess('Admin Create Successfully');
        setIsOpen(false);
        setisModalOpen(false);
        setAdminEmail('');
        setPhoneNumber('');
        setAdminPassword('');
        setFirstName('');
        setLastName('');
      } else {
        toastError(response.message);
      }
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };
  return (
    <div className='flex justify-center flex-wrap flex-none m-4 '>
      <Formik
        initialValues={{
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          email: adminEmail,
          adminPassword,
        }}
        validationSchema={craeteAdminSchema}
        validateOnBlur={true}
        onSubmit={updatedValues => {
          createAdminHandler(updatedValues);
        }}
        validateOnChange={true}
      >
        {({ errors, values, handleChange, handleSubmit }) => (
          <Form>
            <FormInput
              placeholder='Enter First Name'
              value={values.first_name}
              type='text'
              style='w-96 my-2'
              name='first_name'
              error={errors.first_name}
              onChange={handleChange}
            />
            <FormInput
              placeholder='Enter Last Name'
              value={values.last_name}
              type='text'
              style='w-96 my-2'
              error={errors.last_name}
              name='last_name'
              onChange={handleChange}
            />
            <FormInput
              placeholder='Enter Email'
              value={values.email}
              type='text'
              style='w-96 my-2'
              name='email'
              error={errors.email}
              onChange={handleChange}
            />
            {/* <FormInput
              placeholder='Enter Phone Number'
              value={values.phone_number}
              type='text'
              name='phone_number'
              // error={errors.phone_number}
              style='w-96 my-2'
              onChange={handleChange}
            /> */}
            <FormInput
              placeholder='Enter Password'
              value={values.adminPassword}
              type='password'
              name='adminPassword'
              error={errors.adminPassword}
              style='w-96 my-2'
              onChange={handleChange}
            />
            <div className='flex justify-center'>
              <Button
                text='Create'
                style='w-40 bg-darkblue mt-3 rounded-md disable'
                onClick={handleSubmit}
                disabled={Object.keys(errors).length ? true : false}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateAdmin;
