import Button from '../Buttons/button';

const RejectTransaction = ({ closeModal, rejectHandler }: any) => {
  return (
    <div className='flex justify-center flex-wrap '>
      <div className=''>
        <h2 className='text-extrabold text-darkblue'>Do you want to reject this Transaction?</h2>
      </div>
      <div className='flex justify-center'>
        <Button
          text='Cancel'
          style='w-40 bg-gray-200 text-darkblue m-4 rounded-md border-2 border-bg-gray-600 hover:bg-gray-100 hover:border-gray-200 active:bg-gray-200 active:ring-gray-200 focus:ring-gray-200 '
          onClick={closeModal}
        />
        <Button
          text='Yes, Reject it!'
          style='bg-red text-white w-40 border-2 border-transparent rounded-md hover:bg-red  m-4 hover:opacity-70 focus:ring-red active:bg-red font-bold '
          onClick={rejectHandler}
        />
      </div>
    </div>
  );
};

export default RejectTransaction;
