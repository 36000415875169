import { useEffect, useState } from 'react';
import Collapsible from 'react-collapsible';
import { FiChevronDown } from 'react-icons/fi';
import { IRA_ADMIN } from '../../../Api/panelApis';
import PortfolioHistoryTable from '../../IRAPanel/Table/PortfolioEditTable';

const PortfolioHistory = ({ userId }: any) => {
  const [history, setHistory] = useState<any>([]);

  const getPortfolioHistory = async () => {
    const response = await IRA_ADMIN.portfolioEditHistory(userId);
    setHistory(response);
  };

  useEffect(() => {
    getPortfolioHistory();
  }, []);

  if (!history?.count) {
    return null;
  }

  return (
    <Collapsible
      open={false}
      tabIndex={0}
      triggerStyle={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px 10px 20px',
        backgroundColor: '#dcdee6',
        borderRadius: '6px',
        margin: '5px 0px',
      }}
      // @ts-ignore
      // eslint-disable-next-line react/jsx-key
      trigger={['Portfolio Edit History', <FiChevronDown size={20} />]}
    >
      <PortfolioHistoryTable editHistory={history?.data} />
    </Collapsible>
  );
};

export default PortfolioHistory;
