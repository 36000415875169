/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

export interface IAppContext {
  allUsersdata: any;
  setAllUsersdata: (allUsersdata: any) => void;
  token: string | null;
  setToken: (token: string) => void;
  infoUpdated: any;
  setInfoUpdated: (infoUpdated: any) => void;
}

export const AppContext = createContext<IAppContext>({
  allUsersdata: '',
  token: '',
  setAllUsersdata: () => {},
  setToken: () => {},
  setInfoUpdated: () => {},
  infoUpdated: () => {},
});
