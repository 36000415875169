import React, { useEffect, useState } from 'react';
import { IRA_ADMIN } from '../../Api/panelApis';

const AllcationsCell: React.FC<{ value: Array<any> }> = ({ value }) => {
  const [allocations, setAllocations] = useState([]);

  const getAllStrategies = async () => {
    const getAll = await IRA_ADMIN.getAllStrategies();
    const strategyWithID: any = [];
    getAll?.map((val: any) => {
      strategyWithID.push({ strategyName: val.title, id: val.id });
    });
    setAllocations(strategyWithID);
    const allocationsWithStrategy: any = [];
    strategyWithID?.forEach((str: any) => {
      value?.find((val: any) => {
        if (str.id === val.strategy) {
          allocationsWithStrategy.push({ strategyName: str.strategyName, allocation: val.alloc_with_cash });
          return true;
        }
      });
    });
    setAllocations(allocationsWithStrategy);
  };

  useEffect(() => {
    getAllStrategies();
  }, []);

  return (
    <div>
      {allocations?.map((alloc: any) => {
        return (
          <div className='text-center' key={alloc.strategyName}>
            {alloc.strategyName} - {alloc.allocation * 100}%
          </div>
        );
      })}
    </div>
  );
};

export default AllcationsCell;
