import React from 'react';
import './EditDetails.css';
import { IRA_ADMIN } from '../../Api/panelApis';
import EditPersonalInfo from './EditSection/editPersonalInfo';
import EditAccountInfo from './EditSection/editAccountInfo';
import EditAddressInfo from './EditSection/editAddressInfo';
import EditAddressDocs from './EditSection/editAddressDocs';
import EditNotes from './EditSection/editNotes';
import EditKYCInfo from './EditSection/editKYCInfo';
import EditOtherDocument from './EditSection/editOtherDocument';
import { toastSuccess, toastError } from '../../Utils/helper';
import { Form, Formik } from 'formik';
import { UserSchema } from '../../Validation/validationScema';
import { Investor_Type, STATUS } from '../../Constant/IRAPanelData';
import EditAuthProofDocs from './EditSection/editAuthProofDoc';
import { EditUserDetailsProps } from '../../Constant/Types/types';
import EditPortfolioInfo from './EditSection/EditPortfolioInfo';
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderBar from '../../components/headerBar/headerBar';
import EditAccreditationDocs from './EditSection/EditAccreditationDocs';

interface CustomizedState {
  userDetails: any;
  userId: any;
  portfolioDetail: any;
  strategies: any;
}

const EditDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const state = location.state as CustomizedState;
  // const { userDetails, userId, portfolioDetail, strategies } = state;
  // TypeScript-safe way to handle localStorage
  const state =
    (location.state as CustomizedState) ||
    (localStorage.getItem('editUserData') ? JSON.parse(localStorage.getItem('editUserData') as string) : null);

  const { userDetails, userId, portfolioDetail, strategies } = state;
  const initialValues = {
    date_of_birth: userDetails?.personalinfodata?.dob || '',
    email: userDetails?.personalinfodata?.email || '',
    first_name: userDetails?.personalinfodata?.first_name || '',
    last_name: userDetails?.personalinfodata?.last_name || '',
    phone_number: userDetails?.personalinfodata.phone_number || '',
    Address1: userDetails?.addressinfodata?.address1 || '',
    Address2: userDetails?.addressinfodata?.address2 || null,
    Amount: userDetails?.accountinfo?.custodian_amount || '',
    City: userDetails?.addressinfodata.city || '',
    Country: userDetails.addressinfodata.country || 'United State',
    SSN: userDetails?.personalinfodata?.ssn || '',
    EIN: userDetails?.personalinfodata?.ein_number || '',
    companyName: userDetails?.personalinfodata?.company_name || '',
    State: userDetails?.addressinfodata?.state || '',
    custodianAccountName: userDetails?.accountinfo?.custodian_account_name || '',
    custodianAccountNumber: userDetails?.accountinfo?.custodian_account_number || '',
    imageURL: userDetails?.kycinfodata?.frontImageUrl || '',
    initialAccountContribution: userDetails?.accountinfo?.initial_ira_amount || '',
    maritalStatus: userDetails?.personalinfodata?.marrital_status ? true : false,
    zipCode: userDetails?.addressinfodata?.zipCode || '',
    transferAccount: userDetails?.accountinfo?.ira_account_type || '',
    accreditaionStatus: userDetails?.personalinfodata?.accreditation_type || '0',
    documentType: userDetails?.kycinfodata?.documentType || '1',
    AddressDocumentType: userDetails?.kycinfodata?.documentType || '1',
    AddressDocument: userDetails?.addressinfodata?.docFile || null,
    AccreditedDocumentType: userDetails?.kycinfodata?.documentType || '1',
    AccreditedDocument: userDetails?.accreditedinvestordata || null,
    investorType: userDetails?.accountinfo?.investor_type || '',
    ira_type: userDetails?.accountinfo?.ira_type || '0',
    frontImage: userDetails?.kycinfodata?.frontImageUrl,
    backImage: userDetails?.kycinfodata?.backImageUrl || '',
    otherDocument: '',
    authProofDocument: userDetails?.proof_of_auth || null,
  };
  const {
    personalinfodata: { investor_type, application_status },
  } = userDetails;
  const [values, setValues] = React.useState(initialValues);
  const [documentFileType, setdocumentFileType] = React.useState('');
  const [otherDocument, setOtherDocument] = React.useState<any>([{ documentName: '', otherDocumentFile: '', otherDocumentFileURL: '' }]);
  const [loading, setLoading] = React.useState(false);
  const updateHander = async (user: EditUserDetailsProps, investor_type: string) => {
    setLoading(true);
    const updatedData = {
      data: {
        personalinfodata: {
          first_name: user.first_name,
          last_name: user.last_name,
          phone_number: user.phone_number,
          email: user.email,
          dob: user.dob,
          ssn: user.ssn,
          ein_number: user.ein,
          marrital_status: user.marrital_status,
          accreditation_type: user.accreditation_type,
          company_name: user.company_name,
          citizenship: '',
        },
        accountinfo: {
          ira_type: user.ira_type,
          initial_ira_amount: user.initial_ira_amount,
          ira_account_type: user.ira_account_type,
          custodian_amount: user?.custodian_amount || 0.0,
          investor_type: 3,
          custodian_account_number: user.custodian_account_number || '',
          custodian_account_name: user.custodian_account_name || '',
        },
        addressinfodata: {
          address1: user.address1,
          address2: user.address2,
          state: user.state || null,
          city: user.city,
          zipCode: user.zipCode,
          country: user.country,
        },
      },
    };
    try {
      const response = await IRA_ADMIN.updateUserDetails(updatedData, userId, investor_type);
      if (response.success) {
        toastSuccess('User updated successfully.');
        setLoading(false);
        if (location.pathname === '/editDetails') {
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };

  const handleInputChange = (e: any, index: number, type: string) => {
    if (type === 'name') {
      const { name, value } = e.target;
      const list = [...otherDocument];
      list[index][name] = value;
      setOtherDocument(list);
    } else {
      const { name } = e.target;
      const value = e.target.files[0];
      const list = [...otherDocument];
      list[index][name] = value;
      setOtherDocument(list);
    }
  };
  const handleRemoveClick = (index: number) => {
    const list = [...otherDocument];
    list.splice(index, 1);
    setOtherDocument(list);
  };

  const handleAddClick = () => {
    setOtherDocument([...otherDocument, { documentName: '', otherDocumentFile: '' }]);
  };

  const convertBase64 = (image: Blob, setValue: any, index: number) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      try {
        fileReader.onload = () => {
          resolve(fileReader.result);
          if (setValue === 'otherDocumentFileURL') {
            const list = [...otherDocument];
            list[index][setValue] = fileReader.result;
            setOtherDocument(list);
          } else {
            setValue(fileReader.result);
          }
        };
      } catch {
        fileReader.onerror = (error: any) => {
          reject(error);
        };
      }
    });
  };
  const editUserDetails = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };
  const otherDocsHandler = async (i: number) => {
    setLoading(true);
    const data = {
      document_name: otherDocument[i].documentName,
      other_document: otherDocument[i].otherDocumentFile,
    };
    try {
      const response = await IRA_ADMIN.otherDocument(data, userId);
      if (response.success) {
        toastSuccess('Other documents updated successfully.');
        setLoading(false);
        if (location.pathname === '/editDetails') {
          setTimeout(() => {
            navigate(-1);
          }, 2000);
        } else {
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      }
    } catch (error: any) {
      toastError(error.response.data.error || error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <div className='w-full max-w-8xl'>
      <HeaderBar title={`Edit Onboarding Application`} />
      <div
        className='inline-block w-full max-w-8xl p-6 mb-8 overflow-hidden text-left align-middle transition-all transform'
        style={{ backgroundColor: '#f2f4ff' }}
      >
        <div
          className='w-full max-w-8xl p-6 my-2 overflow-auto text-left align-middle transition-all transform bg-white shadow-xl rounded-2x2'
          style={{ height: 'calc(100vh - 6rem)' }}
        >
          <Formik
            initialValues={{
              first_name: values.first_name,
              last_name: values.last_name,
              phone_number: values.phone_number,
              email: values.email,
              dob: values.date_of_birth,
              ssn: values.SSN,
              ein: values.EIN,
              company_name: values.companyName,
              marrital_status: values.maritalStatus,
              accreditation_type: values.accreditaionStatus,
              ira_type: values.ira_type,
              initial_ira_amount: values.initialAccountContribution,
              ira_account_type: values.transferAccount,
              custodian_amount: values.Amount || 0.0,
              investor_type: 3,
              custodian_account_number: values.custodianAccountNumber || '',
              custodian_account_name: values.custodianAccountName || '',
              address1: values.Address1,
              address2: values.Address2,
              state: values.State,
              city: values.City,
              zipCode: values.zipCode,
              country: values.Country,
            }}
            validationSchema={UserSchema(investor_type)}
            validateOnBlur={true}
            onSubmit={updatedValues => {
              updateHander(updatedValues, investor_type);
            }}
            validateOnChange={true}
          >
            {({ errors, values: userApplication, handleChange, handleSubmit }) => (
              <Form>
                <EditPersonalInfo
                  investor_type={investor_type}
                  errors={errors}
                  handleChange={handleChange}
                  userApplication={userApplication}
                />
                {userDetails?.accountinfo?.ira_account_type ? (
                  <EditAccountInfo errors={errors} handleChange={handleChange} userApplication={userApplication} />
                ) : null}
                <EditAddressInfo
                  investor_type={investor_type}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  userApplication={userApplication}
                  errors={errors}
                  loading={loading}
                  ssn={values.SSN}
                />
              </Form>
            )}
          </Formik>

          <EditAddressDocs
            investor_type={investor_type}
            editUserDetails={editUserDetails}
            values={values}
            documentFileType={documentFileType}
            setdocumentFileType={setdocumentFileType}
            userId={userId}
            convertBase64={convertBase64}
          />
          <EditAccreditationDocs
            investor_type={investor_type}
            editUserDetails={editUserDetails}
            values={values}
            documentFileType={documentFileType}
            setdocumentFileType={setdocumentFileType}
            userId={userId}
            convertBase64={convertBase64}
          />
          <EditNotes
            investor_type={investor_type}
            editUserDetails={editUserDetails}
            values={values}
            documentFileType={documentFileType}
            setdocumentFileType={setdocumentFileType}
            userId={userId}
            convertBase64={convertBase64}
          />
          {investor_type === Investor_Type.US_ENTITY || investor_type === Investor_Type.NON_US_ENTITY ? (
            <EditAuthProofDocs
              editUserDetails={editUserDetails}
              values={values}
              documentFileType={documentFileType}
              setdocumentFileType={setdocumentFileType}
              userId={userId}
              convertBase64={convertBase64}
            />
          ) : null}
          <EditKYCInfo
            editUserDetails={editUserDetails}
            values={values}
            convertBase64={convertBase64}
            userId={userId}
            investor_type={investor_type}
          />
          <EditOtherDocument
            otherDocument={otherDocument}
            handleInputChange={handleInputChange}
            handleRemoveClick={handleRemoveClick}
            handleAddClick={handleAddClick}
            otherDocsHandler={otherDocsHandler}
            convertBase64={convertBase64}
            userDetails={userDetails}
            userId={userId}
            loading={loading}
          />
          {application_status === STATUS.APPROVED ? (
            <EditPortfolioInfo userId={userId} portfolioDetail={portfolioDetail} strategies={strategies} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default EditDetails;
