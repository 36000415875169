import AllcationsCell from '../../components/Cells/allcationsCell';
import DateCell from '../../components/Cells/dateCell';
import FullNameCell from '../../components/Cells/fullName';
import ImageCell from '../../components/Cells/imageCell';
import LargeValue from '../../components/Cells/largeValueCell';
import StatusCell from '../../components/Cells/statusCell';
import Transaction_TypeCell from '../../components/Cells/transactionTypeCell';
import Transaction_StatusCell from '../../components/Cells/transctionStatusCell';

export const COLUMNS = [
  {
    Header: 'User ID',
    Footer: 'User ID',
    accessor: 'id',
    disableFilters: true,
    sticky: 'left',
  },
  {
    Header: 'Full Name',
    Footer: 'Full Name',
    accessor: 'fullname',
    // accessor: (row: { first_name: string; last_name: string }) =>
    //   `${row?.first_name?.length > 15 ? row?.first_name?.slice(0, 12) + '...' : row?.first_name} ${
    //     row?.last_name?.length > 15 ? row?.last_name?.slice(0, 12) + '...' : row?.last_name
    //   }`,
    // Cell: FullNameCell,
  },

  // {
  //   Header: 'Phone Number',
  //   Footer: 'Phone Number',
  //   accessor: 'phone_number',
  //   maxWidth: 20,
  // },
  {
    Header: 'Email',
    Footer: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Type',
    Footer: 'User Type',
    accessor: 'user_type',
  },
  {
    Header: 'Started On',
    Footer: 'Started On',
    accessor: `started_at`,
    Cell: DateCell,
  },
  {
    Header: 'Days Incomplete',
    Footer: 'Days Incomplete',
    accessor: 'pending_from',
  },

  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'application_status',
    Cell: StatusCell,
    disableSortBy: true, // Disable sorting for this column
  },
];

export const Leads_COLUMNS = [
  {
    Header: 'User ID',
    Footer: 'User ID',
    accessor: 'id',
    sticky: 'left',
  },
  {
    Header: 'Phone Number',
    Footer: 'Phone Number',
    accessor: 'phone_number',
  },
  {
    Header: 'Started On',
    Footer: 'Started On',
    accessor: 'started_at',
    Cell: DateCell,
  },
];

export const leadsDetailsColumn = [
  {
    Header: 'First Name',
    Footer: 'First Name',
  },
  {
    Header: 'Last Name',
    Footer: 'Last Name',
  },
  {
    Header: 'Address',
    Footer: 'Address',
  },

  {
    Header: 'DOB',
    Footer: 'DOB',
  },
  {
    Header: 'SSN',
    Footer: 'SSN',
  },
];

export const Transaction_COLUMNS = [
  {
    Header: 'User ID',
    Footer: 'User ID',
    accessor: 'id',
    sticky: 'left',
  },
  {
    Header: 'Phone Number',
    Footer: 'Phone Number',
    accessor: 'phone_number',
  },
  {
    Header: 'Started On',
    Footer: 'Started On',
    accessor: 'user_started_at',
    Cell: DateCell,
  },
  {
    Header: 'Full Name',
    Footer: 'Full Name',
    accessor: (row: { first_name: string; last_name: string }) =>
      `${row?.first_name?.length > 15 ? row?.first_name?.slice(0, 12) + '...' : row?.first_name} ${
        row?.last_name?.length > 15 ? row?.last_name?.slice(0, 12) + '...' : row?.last_name
      }`,
    Cell: FullNameCell,
  },
  {
    Header: 'Transaction ID',
    Footer: 'Transaction ID',
    accessor: 'transaction id',
  },
  {
    Header: 'Transaction Amount',
    Footer: 'Transaction Amount',
    accessor: 'transaction amount',
  },
  {
    Header: 'Transaction Start Date',
    Footer: 'Transaction Start Date',
    accessor: 'transaction start date',
    Cell: DateCell,
  },
  {
    Header: 'Transaction End Date',
    Footer: 'Transaction End Date',
    accessor: 'transaction end date',
    Cell: DateCell,
  },
  {
    Header: 'Document',
    Footer: 'Document',
    accessor: 'transaction document',
    Cell: ImageCell,
    disableSortBy: true, // Disable sorting for this column
  },
  {
    Header: 'Transaction Type',
    Footer: 'Type',
    accessor: 'transaction type',
    Cell: Transaction_TypeCell,
  },
  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'transaction status',
    Cell: Transaction_StatusCell,
    disableSortBy: true, // Disable sorting for this column
  },
  {
    Header: 'Details',
    Footer: 'Details',
    accessor: 'detail',
  },
];
export const Strategies_COLUMNS = [
  {
    Header: 'Strategy ID',
    Footer: 'Strategy ID',
    accessor: 'id',
    sticky: 'left',
  },
  {
    Header: 'Strategy Name',
    Footer: 'Strategy Name',
    accessor: 'name',
  },
  {
    Header: 'Invested Amount',
    Footer: 'Invested Amount',
    accessor: 'invested_amount',
  },
];

export const USER_Transaction_COLUMNS = [
  {
    Header: 'Transaction ID',
    Footer: 'Transaction ID',
    accessor: 'transaction id',
  },
  {
    Header: 'Transaction Amount',
    Footer: 'Transaction Amount',
    accessor: 'transaction amount',
  },
  {
    Header: 'Transaction Start Date',
    Footer: 'Transaction Start Date',
    accessor: 'transaction start date',
    Cell: DateCell,
  },
  {
    Header: 'Transaction End Date',
    Footer: 'Transaction End Date',
    accessor: 'transaction end date',
    Cell: DateCell,
  },
  {
    Header: 'Document',
    Footer: 'Document',
    accessor: 'transaction document',
    Cell: ImageCell,
    disableSortBy: true, // Disable sorting for this column
  },
  {
    Header: 'Transaction Type',
    Footer: 'Type',
    accessor: 'transaction type',
    Cell: Transaction_TypeCell,
  },
  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'transaction status',
    Cell: Transaction_StatusCell,
    disableSortBy: true, // Disable sorting for this column
  },
];

export const Investors_COLUMNS = [
  {
    Header: 'User ID',
    Footer: 'User ID',
    accessor: 'id',
    sticky: 'left',
  },
  {
    Header: 'Email',
    Footer: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Phone Number',
    Footer: 'Phone Number',
    accessor: 'phone_number',
  },
  {
    Header: 'Full Name',
    Footer: 'Full Name',
    accessor: (row: { first_name: string; last_name: string }) =>
      `${row?.first_name?.length > 15 ? row.first_name?.slice(0, 12) + '...' : row?.first_name} ${
        row?.last_name?.length > 15 ? row?.last_name?.slice(0, 12) + '...' : row?.last_name
      }`,
    Cell: FullNameCell,
  },
  {
    Header: 'Carl Investor',
    Footer: 'Carl Investor',
    accessor: (row: { investment_cash: number }) => `${row?.investment_cash < 1 ? 'No' : 'Yes'}`,
  },
  // {
  //   Header: 'Investment Cash',
  //   Footer: 'Investment Cash',
  //   accessor: 'investment_cash',
  // },
  // {
  //   Header: 'Available Cash',
  //   Footer: 'Available Cash',
  //   accessor: 'available_cash',
  // },
  // {
  //   Header: 'Unavailable Cash',
  //   Footer: 'Unavailable Cash',
  //   accessor: 'unavailable_cash',
  //   sortable: false,
  // },
];

export const Support_Request_COLUMN = [
  {
    Header: 'ID',
    Footer: 'ID',
    accessor: 'id',
    sticky: 'left',
  },
  {
    Header: 'Email',
    Footer: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Subject',
    Footer: 'Subject',
    accessor: 'subject',
    Cell: LargeValue,
  },
  {
    Header: 'Message',
    Footer: 'Message',
    accessor: 'message',
    Cell: LargeValue,
  },
  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'status',
    disableSortBy: true, // Disable sorting for this column
  },
  {
    Header: 'Created At',
    Footer: 'Created At',
    accessor: 'created_at',
    Cell: DateCell,
  },
  {
    Header: 'Updated At',
    Footer: 'Updated At',
    accessor: 'updated_at',
    Cell: DateCell,
  },
];

export const Invested_Users_COLUMNS = [
  {
    Header: 'User ID',
    Footer: 'User ID',
    accessor: 'user_id',
  },
  {
    Header: 'Name',
    Footer: 'Name',
    accessor: 'user_name',
  },
  {
    Header: 'Invested Amount',
    Footer: 'Invested Amount',
    accessor: 'invested_amount',
  },
];

export const Portfolio_History_COLUMN = [
  {
    Header: 'Portfolio ID',
    Footer: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Portfolio Name',
    Footer: 'Portfolio Name',
    accessor: 'name',
  },
  {
    Header: 'Edited Date',
    Footer: 'Edited Date',
    accessor: 'date',
    Cell: DateCell,
  },
  {
    Header: 'Allocations',
    Footer: 'Allocations',
    accessor: 'allocations_json',
    Cell: AllcationsCell,
  },
];

export const Dwolla_Logs_COLUMN = [
  {
    Header: 'Log Message',
    Footer: 'Log Message',
    accessor: 'log_message',
  },
  {
    Header: 'KYC Document Score',
    Footer: 'KYC Document Score',
    accessor: 'kyc_score',
  },
  {
    Header: 'Date',
    Footer: 'Date',
    accessor: 'date',
    Cell: DateCell,
  },
];
