import { ArcElement, Chart, Title } from 'chart.js';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Button from '../../../components/Buttons/button';
import Details from '../../../components/Details/details';
import Modal from '../../../components/Modal/modal';
Chart.register(ArcElement);
Chart.register(Title);

const EditPortfolioInfo = ({ userId, portfolioDetail, strategies }: any) => {
  const [toggleModal, setToggleModal] = useState(false);

  const onClick = () => {
    setToggleModal(true);
  };

  const options = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: strategies.map((v: any) => v.strategyName),
    datasets: [
      {
        data: strategies.map((v: any) => v.allocation * 100),
        backgroundColor: [
          'rgb(233, 226, 227)',
          'rgb(222, 141, 160)',
          'rgb(144, 179, 235)',
          'rgb(200, 198, 129)',
          'rgb(235, 180, 52)',
          'rgb(52, 235, 64)',
          'rgb(250 170 173)',
          'rgb(52, 73, 235)',
          'rgb(240, 86, 114)',
          'rgb(166, 240, 86)',
        ],
      },
    ],
  };

  return (
    <div>
      <div className='mx-4 pb-4 px-3'>
        <div className='flex justify-between'>
          <h2 className='text-lg font-medium mt-8 heading'>PORTFOLIO INFO</h2>
        </div>
      </div>
      <hr className='hrLine' />
      <div className='alignment my-10'>
        {portfolioDetail?.id ? (
          <>
            <div className='flex flex-col justify-center w-full'>
              <div className='mx-4'>
                <div className='grid grid-cols-3'>
                  <Details heading='Portfolio Name' detail={portfolioDetail?.name} />
                  <Details heading='Created Date' detail={dayjs(portfolioDetail?.date).format('DD-MM-YYYY')} />
                  <Details heading='Type' detail={portfolioDetail?.portfolio_type} />
                  <Details heading='Currency' detail={portfolioDetail?.currency} />
                  <Details heading='Status' detail={portfolioDetail?.is_active ? 'Active' : 'Not Active'} />
                </div>
                {strategies ? (
                  <div className='w-80 mx-auto'>
                    <Doughnut data={data} options={options} />
                  </div>
                ) : null}
              </div>
              <div>
                <Button
                  text={`Edit Portfolio`}
                  style='w-40 bg-darkblue border-2 border-transparent my-10 mx-auto rounded-md px-4'
                  onClick={onClick}
                />
              </div>
            </div>
          </>
        ) : (
          <p className='text-center'>
            Portfolio is not available,{' '}
            <span className='text-[#E879F9] hover:cursor-pointer' onClick={onClick}>
              Click here to create Portfolio
            </span>
          </p>
        )}
      </div>
      <Modal
        style='max-w-5xl mt-12'
        userId={userId}
        createPortfolio={true}
        setisModalOpen={setToggleModal}
        isModalOpen={toggleModal}
        portfolioDetail={portfolioDetail}
        isPortfolioEdit={true}
      />
    </div>
  );
};

export default EditPortfolioInfo;
