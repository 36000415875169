import MainRoutes from './routes/routes';
import 'react-toastify/dist/ReactToastify.css';
import UsersData from './Context/ContextProvider';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <div className='App'>
      <UsersData>
        <ToastContainer />
        <MainRoutes />
      </UsersData>
    </div>
  );
}

export default App;
