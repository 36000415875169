import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import UserTransactionTable from '../../IRAPanel/Table/UserTransactionTable';

const UserTransactionInfo = ({ transaction, userId }: any) => {
  return (
    <CollapsibleContainer title='TRANSACTION INFO'>
      <div className='mt-6 px-6'>
        <div>
          <UserTransactionTable transactionsList={transaction} userId={userId} />
        </div>
      </div>
    </CollapsibleContainer>
  );
};

export default UserTransactionInfo;
