// import { useState } from 'react';
// import { useEffect, useState } from 'react';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import UploadOtherDocument from './uploadOtherDocument';
// import LeadsTable from '../../IRAPanel/Table/leadsTable';
// import Modal from '../../../components/Modal/modal';
// import Details from '../../../components/Details/details';
// import { ACCREDITATION_STATUS_SELECT, Investor_Type, isIRA } from '../../../Constant/IRAPanelData';
// import { isPaperApp } from '../../../Utils/helper';
// interface PersonalInfoProps {
//   userDetails: any;
// }
const PersonalInfo = ({ userDetails, userId }: any) => {
  // const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <CollapsibleContainer title='THIRD PARTY INFORMATION'>
      {/* <div className='mt-6 px-6'>
        {isModalOpen ? (
          <Modal
            style='max-w-5xl'
            leadsUsers={true}
            coginitoData={true}
            setisModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            leadUserData={userDetails}
          />
        ) : null}
        <div className='grid grid-cols-3 my-5'>
          <button className='px-4 py-2 alignment rounded-md text-white	font-normal bg-darkblue' onClick={() => setIsModalOpen(true)}>
            View Cognito Details
          </button>
        </div>
      </div> */}
      <UploadOtherDocument userDetails={userDetails} userId={userId} documentType='thirdParty' />
    </CollapsibleContainer>
  );
};

export default PersonalInfo;
