import dayjs from 'dayjs';
import Details from '../../../components/Details/details';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Title } from 'chart.js';
import 'chart.js/auto';
import Button from '../../../components/Buttons/button';
import { IRA_ADMIN } from '../../../Api/panelApis';
import { toastError, toastSuccess } from '../../../Utils/helper';
import { useState } from 'react';
import Modal from '../../../components/Modal/modal';
import { useNavigate } from 'react-router-dom';
import PortfolioHistory from './PortfolioHistory';
import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';

Chart.register(ArcElement);
Chart.register(Title);

const PortfolioInfo = ({ portfolioDetail, strategies, userId, infoUpdated, setInfoUpdated }: any) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [createPortModal, setCreatePortModal] = useState(false);
  const navigate = useNavigate();
  const options = {
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: strategies.map((v: any) => v.strategyName),
    datasets: [
      {
        data: strategies.map((v: any) => v.allocation * 100),
        backgroundColor: [
          'rgb(233, 226, 227)',
          'rgb(222, 141, 160)',
          'rgb(144, 179, 235)',
          'rgb(200, 198, 129)',
          'rgb(235, 180, 52)',
          'rgb(52, 235, 64)',
          'rgb(250 170 173)',
          'rgb(52, 73, 235)',
          'rgb(240, 86, 114)',
          'rgb(166, 240, 86)',
        ],
      },
    ],
  };

  const onClick = () => {
    setCreatePortModal(true);
  };

  const verify = async (id: number) => {
    const payload = {
      id,
      op: 'verified',
    };
    const response = await IRA_ADMIN.verifyPortfolio(payload, userId);
    if (response.status === 'success') {
      toastSuccess('Portfolio Verified Successfully');
      setInfoUpdated(!infoUpdated);
    } else {
      toastError('Something went wrong');
    }
  };

  const deleted = async () => {
    setToggleModal(false);
    const payload = {
      id: portfolioDetail?.id,
    };
    try {
      const response = await IRA_ADMIN.deletePortfolio(payload, userId);
      if (response.status === 'success') {
        toastSuccess('Portfolio Deleted Successfully');
        navigate(-1);
      } else {
        toastError('Something went wrong');
      }
    } catch (err) {
      console.log({ err });
    }
  };

  return (
    <>
      <CollapsibleContainer title='PORTFOLIO INFORMATION'>
        <div className='mt-6 px-6'>
          {portfolioDetail?.id ? (
            <>
              <div>
                <div className='grid grid-cols-3'>
                  <Details heading='Portfolio Name' detail={portfolioDetail?.name} />
                  <Details heading='Created Date' detail={dayjs(portfolioDetail?.date).format('DD-MM-YYYY')} />
                  <Details heading='Type' detail={portfolioDetail?.portfolio_type} />
                  <Details heading='Currency' detail={portfolioDetail?.currency} />
                  <Details heading='Status' detail={portfolioDetail?.is_active ? 'Active' : 'Not Active'} />
                </div>
                {strategies ? (
                  <div className='w-80 mx-auto'>
                    <Doughnut data={data} options={options} />
                  </div>
                ) : null}
              </div>
              <div className='alignment my-3'>
                <Button
                  text='VERIFY'
                  style='bg-darkblue border-2 border-transparent m-4 rounded-md px-4'
                  onClick={() => verify(portfolioDetail?.id)}
                />
                <Button
                  text='DELETE'
                  style='bg-red text-white border-2 border-transparent rounded-md hover:bg-red  m-4 hover:opacity-70 focus:ring-red '
                  onClick={() => setToggleModal(true)}
                />
              </div>
            </>
          ) : (
            <div className='mt-10'>
              <p className='text-center'>
                Portfolio is not available,{' '}
                <span className='text-[#E879F9] hover:cursor-pointer' onClick={onClick}>
                  Click here to create Portfolio
                </span>
              </p>
            </div>
          )}
        </div>
        <PortfolioHistory userId={userId} />
        <Modal deletePortfolio={true} deleteHandler={deleted} setisModalOpen={setToggleModal} isModalOpen={toggleModal} />
        <Modal
          style='max-w-5xl mt-12'
          userId={userId}
          createPortfolio={true}
          setisModalOpen={setCreatePortModal}
          isModalOpen={createPortModal}
          portfolioDetail={portfolioDetail || ''}
          isPortfolioEdit={false}
        />
      </CollapsibleContainer>
    </>
  );
};

export default PortfolioInfo;
