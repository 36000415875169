import { Routes, Route, Navigate } from 'react-router-dom';
import Login from '../screens/Login/login';
import EnterOTP from '../screens/EnterOTP/EnterOTP';
import ForgotPassword from '../screens/Forgotpassword/forgotPassword';
import ResetPassword from '../screens/ResetPassword/resetPassword';

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='*' element={<Navigate to='/' />} />
      <Route path='/EnterOTP' element={<EnterOTP />} />
      <Route path='/ForgotPassword' element={<ForgotPassword />} />
      <Route path='/password/reset/:id/:token' element={<ResetPassword />} />
    </Routes>
  );
};
export default PublicRoutes;
