import React from 'react';
import { Transactions_STATUS } from '../../Constant/IRAPanelData';
import StatusPill from '../StatusPill/statusPill';

const Transaction_StatusCell: React.FC<{ value: number }> = ({ value }) => {
  const StatusPills = (value: number) => {
    switch (value) {
      case Transactions_STATUS.STATUS_PROCESSED:
        return <StatusPill textColor='text-darkGreen' backgroundcolor='bg-lightGreen' status={'Approved'} />;
      case Transactions_STATUS.STATUS_ACTIVE:
        return <StatusPill textColor='text-pendingDarkBtn' backgroundcolor='bg-pendingLightBtn' status={'Pending'} />;
      case Transactions_STATUS.STATUS_EXPIRED:
        return <StatusPill textColor='text-darkRed' backgroundcolor='bg-lightRed' status={'Expired'} />;
      case Transactions_STATUS.STATUS_CANCELLED:
        return <StatusPill textColor='text-darkOrange' backgroundcolor='bg-lightOrange' status={'Cancelled'} />;
    }
  };

  return <span>{StatusPills(value)}</span>;
};

export default Transaction_StatusCell;
