import { useState } from 'react';
import { IRA_ADMIN } from '../../Api/panelApis';
import { toastError, toastSuccess, validateEmail } from '../../Utils/helper';
import Button from '../Buttons/button';
import FormInput from '../InputFields/FormInput';

const CreateSupportModal = ({ setisModalOpen }: any) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState(false);

  const createNewRequest = async () => {
    if (validateEmail(email)) {
      const response: any = await IRA_ADMIN.createSupportRequest({ email, subject, message });
      if (response?.id) {
        toastSuccess('Request Created Successfully');
        setisModalOpen(false);
      } else toastError('Something went wrong');
    } else {
      setEmailError(true);
    }
  };

  return (
    <div className='p-0'>
      <div className='w-80 mx-auto m-4'>
        <FormInput
          type='text'
          placeholder='Email Address'
          name='email'
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          style='w-full'
        />
        {emailError && <p className='ml-1 text-xs text-red font-extrabold'>Please enter a valid email address</p>}
        <FormInput
          type='text'
          placeholder='Subject'
          name='email'
          value={subject}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSubject(e.target.value)}
          style='w-full'
        />
        <div className='mb-10'>
          <textarea
            className='resize-none focus:border-darkblue focus:outline-none border-b-2 border-darkblue px-3 py-2 text-darkblue placeholder:text-sm placeholder-gray-400 appearance-none w-full '
            value={message}
            onChange={(e: any) => setMessage(e.target.value)}
            placeholder='Message'
            rows={4}
          >
            {message}
          </textarea>
          {message.length > 256 ? (
            <p className='ml-1 text-xs text-red font-extrabold'>Message should not be greater than 256 character*</p>
          ) : null}
        </div>
        <Button
          text='SUBMIT'
          disabled={!email || !subject || !message || message.length > 256}
          style='mx-auto w-full'
          onClick={createNewRequest}
        />
      </div>
    </div>
  );
};

export default CreateSupportModal;
