import React from 'react';
import PdfPreview from '../DocumentImage/pdfPreview';

const ImageCell: React.FC<{ value: string }> = ({ value }) => {
  const fileExtension = value?.split('.').pop();
  return (
    <div className='w-20 mx-auto'>
      {value ? (
        <>
          <a href={value} target='_blank' rel='noreferrer'>
            {fileExtension === 'pdf' ? <PdfPreview url={value} /> : <img src={value} alt='image' />}
          </a>
        </>
      ) : (
        <span className='text-xs'>---</span>
      )}
    </div>
  );
};

export default ImageCell;
