import { useCallback, useEffect, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { ThreeDots } from 'react-loader-spinner';
import ReactPaginate from 'react-paginate';
import { IRA_ADMIN } from '../../../../Api/panelApis';
import HeaderBar from '../../../../components/headerBar/headerBar';
import InvestorsTable from '../../Table/InvestorsTable';

const Investors = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);
  const [searchData, setSearchData] = useState('');
  const [pageNumber, setPageNumber] = useState(0);

  const getAllInvestors = useCallback(async signal => {
    setIsLoading(true);
    const response = await IRA_ADMIN.investors(signal);
    setTransactionsList(response?.new_investors_but_no_fund || response);
    setIsLoading(false);
  }, []);

  const InvestorsData =
    searchData === ''
      ? transactionsList
      : transactionsList?.filter((user: any) => {
          const fullName = `${user?.first_name} ${user?.last_name}`.toLowerCase();
          return (
            JSON.stringify(user.id).includes(searchData) ||
            user?.phone_number?.includes(searchData) ||
            user?.first_name.toLowerCase().includes(searchData.toLowerCase()) ||
            user?.last_name.toLowerCase().includes(searchData.toLowerCase()) ||
            user?.email?.toLowerCase().includes(searchData.toLowerCase()) ||
            fullName.includes(searchData.toLowerCase())
          );
        });

  const usersPerPage = 10;
  // const pagesVisited = pageNumber * usersPerPage;
  // const displayUsers = transactionsList?.slice(pagesVisited, pagesVisited + usersPerPage);
  const pageCount = Math.ceil(InvestorsData?.length / usersPerPage);
  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const controller = new AbortController();
    getAllInvestors(controller.signal);
    return () => {
      controller.abort();
    };
  }, []);

  // Reset page number to 0 whenever searchData changes
  useEffect(() => {
    setPageNumber(0);
  }, [searchData]);

  return (
    <div className='panelTableContainer'>
      <HeaderBar title={`Investors`} />
      <div className='panelTable'>
        <div className='dropDownSection-big'>
          <div className='panelHeadingContainer'>
            <div className='searchLeadUser'>
              <input type='text' placeholder='Search' className='searchInput' onChange={e => setSearchData(e.target.value)} />
              <MdSearch size={24} color='lightgrey' />
            </div>
          </div>
          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <div className='investortablescroll'>
              <InvestorsTable investors={InvestorsData} pageNumber={pageNumber} usersPerPage={usersPerPage} />
            </div>
          )}
          {InvestorsData.length ? (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
              forcePage={pageNumber}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Investors;
