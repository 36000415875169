import React from 'react';
import { Transactions_TYPE } from '../../Constant/IRAPanelData';

const Transaction_TypeCell: React.FC<{ value: number }> = ({ value }) => {
  const StatusPills = (value: number) => {
    switch (value) {
      case Transactions_TYPE.WITHDRAW:
        return <p>Withdraw</p>;
      case Transactions_TYPE.DEPOSIT:
        return <p>Deposit</p>;
    }
  };

  return <span>{StatusPills(value)}</span>;
};

export default Transaction_TypeCell;
