const DocumentImage = ({ src, fileName }: any) => {
  return (
    <div className='alignmentStartPreview block relative' style={{ height: '130px' }}>
      <a href={src} target='_blank' rel='noreferrer' style={{ height: '130px', display: 'block' }}>
        <img width='100' height='100' src={src} alt='image' className='imageContainerNew mx-auto' />
        <span className='flex items-center justify-center text-center font-semibold text-[#051D56]'>{fileName !== '' ? fileName : ''}</span>
      </a>
    </div>
  );
};

export default DocumentImage;
