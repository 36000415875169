interface ButtonProps {
  text?: string;
  style?: string;
  onClick?: (e: any) => Promise<void> | void;
  icon?: JSX.Element | null;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, style, onClick, icon, disabled }) => {
  return (
    <div>
      <button
        onClick={onClick}
        disabled={disabled}
        className={`px-5 py-2 w-28 text-sm font-extrabold  text-white rounded-xl alignment ${
          disabled && 'bg-disabled'
        } bg-darkblue hover:bg-lightButton active:bg-lightButton disabled:opacity-80 disabled:cursor-not-allowed  focus:outline-none focus:ring focus:ring-lightButton ${style}`}
      >
        {text}
        {icon}
      </button>
    </div>
  );
};

export default Button;
