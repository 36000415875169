import React from 'react';
import { AppContext } from '../Context/Context';

const UsersData: React.FC = (props): React.ReactElement => {
  const [allUsersdata, setAllUsersdata] = React.useState([]);
  const [token, setToken] = React.useState<string | null>('');
  const [infoUpdated, setInfoUpdated] = React.useState(true);

  React.useEffect(() => {
    const getToken = sessionStorage.getItem('token');
    setToken(getToken);
  }, []);

  const userDetails = {
    allUsersdata,
    token,
    infoUpdated,
    setAllUsersdata,
    setToken,
    setInfoUpdated,
  };

  return <AppContext.Provider value={userDetails}>{props.children}</AppContext.Provider>;
};

export default UsersData;
