import { useLocation } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import HeaderBar from '../../components/headerBar/headerBar';
import { useCallback, useEffect, useState } from 'react';
import StrategyDetailsTable from '../IRAPanel/Table/StrategyDetailsTable';
import { IRA_ADMIN } from '../../Api/panelApis';
import ReactPaginate from 'react-paginate';
import { MdSearch } from 'react-icons/md';

interface CustomizedState {
  data: any[];
}

const StrategyDetails = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [searchData, setSearchData] = useState('');
  const [investedUsers, setInvestedUsers] = useState([]);

  // Fallback to localStorage if location.state is undefined or null
  const state = (location.state as CustomizedState) || { data: JSON.parse(localStorage.getItem('strategy_details') || '[]') };
  const { data } = state;

  const displayInvestedUsers =
    searchData === ''
      ? investedUsers
      : investedUsers.filter(
          (user: any) =>
            JSON.stringify(user?.user_id)?.includes(searchData) ||
            JSON.stringify(user?.invested_amount)?.includes(searchData) ||
            user?.user_name.toLowerCase()?.includes(searchData?.toLowerCase()),
        );

  const usersPerPage = 10;
  // const pagesVisited = pageNumber * usersPerPage;
  // const displayUsers = investedUsers.slice(pagesVisited, pagesVisited + usersPerPage);
  const pageCount = Math.ceil(displayInvestedUsers?.length / usersPerPage);
  const changePage = ({ selected }: any) => {
    setPageNumber(selected);
  };

  const showData = data[0].row.cells.map((item: any) => {
    return { [item.key]: item.value };
  });
  const StrategyDetail: any = {};
  showData.map((item: any) => {
    return (StrategyDetail[Object.keys(item)?.[0]] = Object.values(item)?.[0]);
  });

  const getAllInvestedUsers = useCallback(async () => {
    setIsLoading(true);
    const response = await IRA_ADMIN.investedUsers(StrategyDetail['Strategy ID']);
    setInvestedUsers(response);
    setIsLoading(false);
  }, [StrategyDetail]);

  useEffect(() => {
    getAllInvestedUsers();
  }, []);

  return (
    <div className='panelTableContainer'>
      <HeaderBar title='Invested Users Details' />
      <div className='panelTable'>
        <div className='dropDownSection-big'>
          <div className='panelHeadingContainer'>
            <p className='text-2xl font-bold mx-auto'>{StrategyDetail['Strategy Name']}</p>
          </div>
          <div className='panelHeadingContainer'>
            <div className='searchLeadUser'>
              <input type='text' placeholder='Search' className='searchInput' onChange={e => setSearchData(e.target.value)} />
              <MdSearch size={24} color='lightgrey' />
            </div>
          </div>

          {isLoading ? (
            <div className='alignment h-full' style={{ height: '70vh' }}>
              <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
            </div>
          ) : (
            <div className='dashboarddetailstablescroll'>
              <StrategyDetailsTable investedUserLists={displayInvestedUsers} pageNumber={pageNumber} usersPerPage={usersPerPage} />
            </div>
          )}
          {displayInvestedUsers.length ? (
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
              forcePage={pageNumber}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default StrategyDetails;
