/* eslint-disable prettier/prettier */
import './pill.css';
interface StatusPillprops {
  textColor?: string;
  backgroundcolor?: string;
  status?: string;
  icon?: any;
  style?: any;
}
const StatusPill: React.FC<StatusPillprops> = ({ textColor, backgroundcolor, status, icon, style }) => {
  return (
    <div>
      <div className={`p-1 rounded-md ${backgroundcolor} ${style}`}>
        <span className={`text-sm alignment px-1 ${textColor}`}>
          <span className='mr-2'>{icon}</span>
          {status}
        </span>
      </div>
    </div>
  );
};

export default StatusPill;
