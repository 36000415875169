import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { MdModeEditOutline, MdLogout } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import './headerBar.css';
import Modal from '../Modal/modal';
import React from 'react';

interface HeaderBarProps {
  title: string;
  totalApplication?: number;
  pending?: string;
}

const HeaderBar: React.FC<HeaderBarProps> = ({ title, totalApplication, pending }) => {
  const [checkButton, setcheckButton] = useState(false);
  const [isModalOpen, setisModalOpen] = React.useState(false);
  const [createAdmin, setCreateAdmin] = React.useState(false);
  const logoutHandler = () => {
    sessionStorage.clear();
    window.location.reload();
  };
  const adminName = sessionStorage.getItem('adminName');
  return (
    <div className='panelTabBar'>
      <div>
        <h2 className='text-darkblue font-extrabold text-xl	'>{title}</h2>
        {title.includes('Application for') ? <p className='text-lightGrey font-bold'>Application pending: {pending} Day</p> : null}
        {totalApplication ? <p className='text-lightGrey font-bold'>Total Applications : {totalApplication}</p> : null}
      </div>
      <Modal createAdmin={createAdmin} setisModalOpen={setisModalOpen} isModalOpen={isModalOpen} type='changePassword' />
      <div className='flex items-start '>
        <Listbox value={checkButton} onChange={setcheckButton}>
          <Listbox.Button>
            <span className='flex justify-center text-lg '>
              {adminName}
              <img width='23' height='17' src='../Assets/admin-avatar.png' alt='admin-logo' className='ml-1' />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
            <Listbox.Options className='bg-white absolute right-1 mt-6 mr-2  px-2 shadow-lg  py-2 rounded-md z-10'>
              <Listbox.Option value={checkButton} className='text-sm px-4 py-1 hover:bg-darkblue hover:text-white cursor-pointer'>
                <div
                  onClick={() => {
                    setisModalOpen(true), setCreateAdmin(false);
                  }}
                  className='alignmentStart'
                >
                  <MdModeEditOutline />
                  <span className='ml-2'>Change Password</span>
                </div>
              </Listbox.Option>
              <Listbox.Option value={checkButton} className='text-sm px-4 py-1 hover:bg-darkblue hover:text-white cursor-pointer'>
                <div
                  onClick={() => {
                    setisModalOpen(true), setCreateAdmin(true);
                  }}
                  className='alignmentStart'
                >
                  <AiOutlineUser />
                  <span className='ml-2'>Add New User</span>
                </div>
              </Listbox.Option>
              <Listbox.Option value={checkButton} className='text-sm px-4 py-1 hover:bg-darkblue hover:text-white cursor-pointer'>
                <div onClick={logoutHandler} className='alignmentStart'>
                  <MdLogout />
                  <span className='ml-2'>Logout</span>
                </div>
              </Listbox.Option>
            </Listbox.Options>
          </Transition>
        </Listbox>
      </div>
    </div>
  );
};

export default HeaderBar;
