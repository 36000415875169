/* eslint-disable prettier/prettier */
import CountUp from 'react-countup';
import './headerCards.css';
interface HeaderCardsProps {
  title: string;
  value: string;
  icon?: any;
  iconColor?: string;
  iconBackground?: string;
  style?: any;
}

const HeaderCards: React.FC<HeaderCardsProps> = ({ value, title, icon, iconBackground, style }) => {
  return (
    <div className={`mt-4`}>
      <div className={`topCardsContainer ${style || ''}`}>
        <div className={`iconContainer `} style={{ backgroundColor: iconBackground }}>
          {icon}
        </div>
        <div className='p-2'>
          <h1 className='text-2xl'>
            <CountUp start={0} end={Number(value)} duration={1.0} separator=',' />
          </h1>
          <p className='cardsTitle'>{title}</p>
        </div>
      </div>
    </div>
  );
};

export default HeaderCards;
