import { useMemo } from 'react';
import { useTable, useSortBy } from 'react-table';
import { dateSort } from './sortTypes'; // Import the custom date sort function

// Custom cell component for date formatting
const DateCell = ({ value }: any) => {
  const formattedDate = new Date(value).toLocaleDateString(); // Format the date to local date string (without time)
  return <span>{formattedDate}</span>;
};

const DwollaLogsTable = ({ data }: any) => {
  const Dwolla_Logs_COLUMN = useMemo(
    () => [
      {
        Header: 'Log Message',
        Footer: 'Log Message',
        accessor: 'log_message',
      },
      {
        Header: 'Date',
        Footer: 'Date',
        accessor: 'created_at', // Bind the created_at field to this column
        Cell: DateCell, // Use the custom DateCell component to format the date
        sortType: dateSort, // Use the custom dateSort function
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: Dwolla_Logs_COLUMN,
      data: data,
    },
    useSortBy, // Add sorting functionality
  );

  return (
    <>
      {data?.length ? (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, colKey) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())} // Enable sorting on the header
                    key={colKey}
                  >
                    {column.render('Header')}
                    {/* Add sorting indicator */}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell: any, colKey) => {
                    return (
                      <td {...cell.getCellProps()} key={colKey}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <p className='text-center my-4'>No records found</p>
      )}
    </>
  );
};

export default DwollaLogsTable;
