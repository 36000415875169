import { useState } from 'react';
import Button from '../Buttons/button';
import FormInput from '../InputFields/FormInput';
import { toastError, toastSuccess } from '../../Utils/helper';
import { IRA_ADMIN } from '../../Api/panelApis';
import PasswordIcon from '../InputFields/passwordIcon';
interface ForgotModalProps {
  setIsOpen: (value: boolean) => void;
  setisModalOpen: (value: boolean) => void;
  user_id: string | null;
}
const ForgotModal = ({ setIsOpen, setisModalOpen, user_id }: ForgotModalProps) => {
  const [isHide, setIsHide] = useState<boolean>(true);
  const [isHideOld, setIsHideOld] = useState<boolean>(true);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const handleSubmit = async () => {
    if (newPassword.length > 5) {
      try {
        const response = await IRA_ADMIN.changePassword(password, newPassword, Number(user_id));
        if (response.success) {
          toastSuccess(response.message);
          setIsOpen(false);
          setisModalOpen(false);
        }
      } catch (error: any) {
        toastError(error.response.data.message);
      }
    } else {
      toastError('Password must be 6 digits or long.');
    }
  };

  return (
    <div className='flex justify-center flex-wrap m-4 '>
      <>
        <div className='w-96 border-b-2 border-darkblue alignmentBetween'>
          <FormInput
            type={isHideOld ? 'password' : 'text'}
            placeholder='Old Password'
            onChange={e => setPassword(e.target.value)}
            style='w-full border-none'
          />
          <PasswordIcon isHide={isHideOld} setIsHide={setIsHideOld} />
        </div>
      </>
      <div className='w-96 border-b-2 border-darkblue alignmentBetween mt-3'>
        <FormInput
          type={isHide ? 'password' : 'text'}
          placeholder='New Password'
          onChange={e => setNewPassword(e.target.value)}
          style='w-full border-none'
        />
        <PasswordIcon isHide={isHide} setIsHide={setIsHide} />
      </div>
      <div className='flex justify-center'>
        <Button text='Save Changes' style='w-40 bg-darkblue mt-5 rounded-md' onClick={handleSubmit} disabled={!password || !newPassword} />
      </div>
    </div>
  );
};

export default ForgotModal;
