import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import carlLogo from '../../assets/carl-logo.png';
import Button from '../../components/Buttons/button';
// Components Imports
import FormInput from '../../components/InputFields/FormInput';
import { IRA_ADMIN } from '../../Api/panelApis';
import { toastError, toastSuccess } from '../../Utils/helper';
import PasswordIcon from '../../components/InputFields/passwordIcon';

const Login = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isHide, setIsHide] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async () => {
    setLoading(true);
    const response = await IRA_ADMIN.login(email, password);
    if (response.success) {
      toastSuccess('OTP successfully Sent.');
      localStorage.setItem('user_id', response.user_id);
      navigate('/EnterOTP', { replace: true });
    } else {
      setLoading(false);
      toastError(response.message);
    }
  };

  const resetHandler = () => {
    navigate('/ForgotPassword');
  };
  return (
    <div className='alignment min-h-full px-4 py-12 sm:px-6 lg:px-8 mt-10'>
      <div className='w-full max-w-md space-y-8 p-4 rounded-lg shadow-2xl'>
        <div>
          <img className='w-auto h-24 mx-auto' src={carlLogo} alt='logo' />
          <h2 className='mt-6 text-2xl font-bold text-center  text-darkblue'>Welcome to Onboarding Panel</h2>
        </div>
        <FormInput
          type='text'
          placeholder='Email Address'
          name='email'
          value={email}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          style='w-full'
        />
        <div className='w-full border-b-2 border-darkblue alignmentBetween'>
          <FormInput
            type={isHide ? 'password' : 'text'}
            placeholder='Password'
            name='password'
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            style='w-80 border-none'
          />
          <PasswordIcon isHide={isHide} setIsHide={setIsHide} />
        </div>

        <div className='mb-2'>
          <Button
            text={'Login'}
            icon={loading ? <i className='fas fa-circle-notch fa-spin fa-md ml-2'></i> : null}
            style='relative w-full px-4 text-md font-medium text-white bg-darkblue border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
            onClick={handleSubmit}
            disabled={!email || !password || loading}
          />
          <div className='alignment mt-3'>
            <p className='text-sm font-bold text-darkblue cursor-pointer' onClick={resetHandler}>
              Forgot Password?
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
