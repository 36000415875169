import { useEffect, useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { IRA_ADMIN } from '../../Api/panelApis';
import { toastError, toastSuccess } from '../../Utils/helper';
import Button from '../Buttons/button';
import FormInput from '../InputFields/FormInput';

const CreatePortfolioModal = ({ userId, portfolioDetail }: any) => {
  const { id, name, allocations: prevAllocations } = portfolioDetail;
  const [strategies, setStrategies] = useState([]);
  const [allocations, setAllocations] = useState<any>({});
  const [portfolioName, setPortfolioName] = useState(name ? name : '');
  const [showError, setShowError] = useState(false);
  const [totalAllocation, setTotalAllocation] = useState<number>(0);
  const [portfolioError, setPortfolioError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getStrategies();
  }, []);

  const getStrategies = async () => {
    const response = await IRA_ADMIN.getAllStrategies();
    const _allocations: any = {};
    const strategyWithID: any = [];
    response?.map((val: any) => {
      strategyWithID.push({ strategyName: val.title, id: val.id, isDelisted: val.is_stopped });
      _allocations[val.id] = 0;
    });
    const checkInitialAllocations = prevAllocations ? prevAllocations : [];
    const initialAllocations = Object.fromEntries(checkInitialAllocations?.map((all: any) => [all.strategy_id, all.alloc * 100]));
    setStrategies(strategyWithID);
    setAllocations({ ...allocations, ...initialAllocations });
  };

  const incrementAllocation = (id: number) => {
    setAllocations({ ...allocations, [id]: allocations[id] ? allocations[id] + 5 : 5 });
  };

  const decrementAllocation = (id: number) => {
    setAllocations({ ...allocations, [id]: allocations[id] ? allocations[id] - 5 : 0 });
  };

  const value = Object.keys(allocations)
    .map(key => ({ strategy_id: Number(key), alloc: allocations[key] / 100 }))
    .filter((v: any) => {
      if (v.alloc) {
        return {
          strategy_id: v.strategy_id,
          alloc: v.alloc,
        };
      }
    });

  const onSubmit = async () => {
    let total = 0;
    value.map((v: any) => {
      total += v.alloc;
    });
    setTotalAllocation(total);
    if (total < 0.1 || total > 1) {
      setShowError(true);
    } else {
      setShowError(false);
    }
    if (!portfolioName) {
      setPortfolioError(true);
    } else {
      setPortfolioError(false);
    }
    const payload = {
      name: portfolioName,
      allocations: value,
    };
    if (total >= 0.1 && total <= 1 && portfolioName.length > 0) {
      const response = await IRA_ADMIN.createPortfolio(payload, userId);
      if (response.status === 'success') {
        toastSuccess(`Portfolio ${id ? 'Edited' : 'Created'} Successfully`);
        navigate(-1);
      } else {
        toastError('Something went wrong');
      }
    }
  };

  return (
    <div className='p-0'>
      <div className='w-60 mx-auto m-4'>
        <FormInput
          type='text'
          placeholder='Enter portfolio Name'
          name='email'
          value={portfolioName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPortfolioName(e.target.value)}
          style='w-full'
        />
      </div>
      {portfolioError ? <p className='text-red text-sm text-center'>Portfolio Name is required*</p> : null}
      <div className='grid grid-cols-2'>
        <p className='my-2 mx-auto text-2xl'>Strategies</p>
        <p className='my-2 mx-auto text-2xl'>Add Allocations</p>
      </div>
      {strategies?.map((str: any) => {
        if (str.isDelisted) {
          return;
        }
        return (
          <div key={str.id} className='grid grid-cols-2'>
            <div className='flex justify-center items-center w-52 mx-auto'>
              <div className='flex text-[#091B41] space-x-1'>
                <p>{str.strategyName}</p>
              </div>
            </div>
            <div className='flex items-center mx-auto'>
              <div className='flex items-center border rounded border-lightGrey m-1 px-1'>
                <p>
                  <AiOutlineMinus className='select-none' onClick={() => decrementAllocation(str.id)} />
                </p>
                <p className='w-8 text-center'>{allocations[str.id] ? allocations[str.id] : 0}</p>
                <p>
                  <AiOutlinePlus className='select-none' onClick={() => incrementAllocation(str.id)} />
                </p>
              </div>
            </div>
          </div>
        );
      })}
      <div className='w-60 mx-auto my-4'>
        <Button style='mx-auto' text='SUBMIT' onClick={onSubmit} />
      </div>
      {totalAllocation > 1 ? <p className='text-red text-center text-sm'>Maximum Allocation should not be greater than 100%.</p> : null}
      {showError && totalAllocation < 0.1 ? (
        <p className='text-red text-center text-sm'>Minimum Allocation should not be less than 10%.</p>
      ) : null}
    </div>
  );
};

export default CreatePortfolioModal;
