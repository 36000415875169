import dayjs from 'dayjs';
import { Formik, Form } from 'formik';
import { useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { IRA_ADMIN } from '../../Api/panelApis';
import Button from '../../components/Buttons/button';
import DocumentImage from '../../components/DocumentImage/documentImage';
import PdfImage from '../../components/DocumentImage/pdfImage';
import PdfPreview from '../../components/DocumentImage/pdfPreview';
import HeaderBar from '../../components/headerBar/headerBar';
import EditableInput from '../../components/InputFields/EditableInput';
import EditableFiles from '../../components/InputFields/EditFiles';
import { exceptThisSymbols, PDF_MESSAGE } from '../../Constant/IRAPanelData';
import { toastError, toastSuccess, tranactionType } from '../../Utils/helper';
import { transactionSchema } from '../../Validation/validationScema';

interface CustomizedState {
  data: any[];
  isWithDraw: boolean;
  userId: string;
}

interface TransactionDetailsTypes {
  user_id: string;
  transaction_id: string;
  amount: number;
  start_date: string;
  end_date: string;
  transaction_document: string;
  transactions_type: number;
}

const EditTransactions = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [documentFileTypeURL, setDocumentFileTypeURL] = useState('');
  const [documentFileType, setDocumentFileType] = useState<any>('');
  const [isLoading, setIsLoading] = useState(false);
  const state = location.state as CustomizedState;
  const { data, userId } = state;
  const showData = data[0]?.row.cells.map((item: any) => {
    return { [item.key]: item.value };
  });
  const transDetails: any = {};
  showData?.map((item: any) => {
    return (transDetails[Object.keys(item)?.[0]] = Object.values(item)?.[0]);
  });

  const initialValues: TransactionDetailsTypes = {
    user_id: transDetails['User ID'],
    transaction_id: transDetails['Transaction ID'],
    amount: transDetails['Transaction Amount'],
    start_date: transDetails['Transaction Start Date'],
    end_date: transDetails['Transaction End Date'],
    transaction_document: transDetails['Document'],
    transactions_type: transDetails['Transaction Type'],
  };

  const fileName = initialValues?.transaction_document;
  const fileExtension = fileName?.split('.').pop();

  const updateHander = async (updatedValues: TransactionDetailsTypes) => {
    setIsLoading(true);
    const updatedData = {
      user_id: initialValues.user_id || userId,
      transaction_id: updatedValues.transaction_id,
      amount: updatedValues.amount,
      start_date: dayjs(updatedValues.start_date).format('YYYY-MM-DD'),
      end_date: dayjs(updatedValues.end_date).format('YYYY-MM-DD'),
      transaction_document: documentFileType,
      transactions_type: tranactionType(initialValues.transactions_type).toLocaleLowerCase(),
    };
    try {
      const response = await IRA_ADMIN.fundsTransactionUpdate(updatedData);
      if (response.success) {
        toastSuccess('Transaction updated successfully');
        setIsLoading(false);
        setTimeout(() => {
          navigate(-2);
        }, 2000);
      }
    } catch (error: any) {
      toastError(error.response.data.message);
    }
  };

  const convertBase64 = (image: Blob, setValue: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(image);
      try {
        fileReader.onload = () => {
          resolve(fileReader.result);
          setValue(fileReader.result);
        };
      } catch {
        fileReader.onerror = (error: any) => {
          reject(error);
        };
      }
    });
  };

  const handleClick = () => {
    console.log('Clicked');
  };

  return (
    <div className='w-full max-w-8xl'>
      <HeaderBar title='Edit Transaction' />
      <div className='w-full max-w-8xl'>
        <div className='inline-block w-full max-w-8xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-[#F2F4FF] rounded-2xl '>
          <div className='w-full max-w-8xl p-6 my-4 overflow-hidden text-left align-middle transition-all transform bg-white h-100 shadow-xl rounded-2x2'>
            <div className='mx-4 pb-4 px-3'>
              <h2 className='text-lg text-blue-600 font-medium mt-8 heading'>TRANSACTION INFO</h2>
            </div>
            <hr className='hrLine' />
            {isLoading ? (
              <div className='alignment h-full' style={{ height: '70vh' }}>
                <ThreeDots height='100' width='100' color='#051D56' ariaLabel='loading-indicator' />
              </div>
            ) : (
              <div className='mt-6 px-6'>
                <Formik
                  initialValues={initialValues}
                  validationSchema={transactionSchema}
                  validateOnBlur={true}
                  onSubmit={updatedValues => {
                    updateHander(updatedValues);
                  }}
                  validateOnChange={true}
                >
                  {({ values: transactionDetails, errors, handleChange, handleSubmit }) => (
                    <Form>
                      <div className='grid  mx-6 grid-cols-3'>
                        <EditableInput
                          value={transactionDetails.amount}
                          onChange={handleChange}
                          className='inputStyle'
                          type='number'
                          heading='Amount'
                          name='amount'
                          error={errors.amount}
                          onKeyDown={(e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        />
                        <EditableInput
                          value={dayjs(transactionDetails.start_date).format('YYYY-MM-DD')}
                          onChange={handleChange}
                          className='inputStyle'
                          type='date'
                          name='start_date'
                          heading='Start Date'
                          error={errors.start_date}
                        />
                        <EditableInput
                          value={dayjs(transactionDetails.end_date).format('YYYY-MM-DD')}
                          onChange={handleChange}
                          className='inputStyle'
                          type='date'
                          name='end_date'
                          heading='End Date'
                          error={
                            // dayjs(transactionDetails.start_date).diff(dayjs(transactionDetails.end_date).format('YYYY-MM-DD'), 'day') > -2
                            // ? 'End Date should be 2 days greater than Start Date'
                            // :
                            errors.end_date
                          }
                        />
                      </div>
                      <div className='mx-4 pb-4 px-3'>
                        <h2 className='text-sm text-blue-600 font-medium mt-4 heading'>TRANSACTION DOCUMENT</h2>
                      </div>
                      <hr className='hrLine' />
                      <div className='flex-col alignment mt-5'>
                        {documentFileTypeURL ? (
                          documentFileType?.type === 'application/pdf' ? (
                            <PdfImage title={documentFileType?.name} />
                          ) : (
                            <>{documentFileType ? <DocumentImage src={documentFileTypeURL} /> : null}</>
                          )
                        ) : initialValues.transaction_document ? (
                          <>
                            {fileExtension === 'pdf' ? (
                              <PdfPreview url={initialValues.transaction_document} />
                            ) : (
                              <DocumentImage src={initialValues.transaction_document} />
                            )}
                          </>
                        ) : null}
                        <EditableFiles
                          type='file'
                          imageUrl={documentFileType}
                          onChange={(e: any) => {
                            convertBase64(e.target.files[0], setDocumentFileTypeURL), setDocumentFileType(e.target.files[0]);
                          }}
                          name='documentFile'
                          placeholder={documentFileTypeURL || initialValues.transaction_document ? null : PDF_MESSAGE}
                          title={documentFileTypeURL || initialValues.transaction_document ? 'Change Document' : 'Upload'}
                          parentStyle={documentFileTypeURL || initialValues.transaction_document ? null : 'bg-grey h-52 p-5 rounded-lg'}
                          uploadButtonStyle={
                            documentFileTypeURL || initialValues.transaction_document ? 'bg-transparent text-darkblue  w-40' : 'bg-pink'
                          }
                          docTypes={'application/pdf'}
                          onclick={handleClick}
                        />
                      </div>
                      <div className='mt-6'>
                        <Button
                          text='Save Transaction'
                          disabled={
                            Boolean(Object.keys(errors).length)
                            // || dayjs(transactionDetails.start_date).diff(dayjs(transactionDetails.end_date).format('YYYY-MM-DD'), 'day') > -2
                          }
                          style='m-1 w-40 mx-auto text-center bg-darkblue'
                          onClick={handleSubmit}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTransactions;
