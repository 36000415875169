import './dropdown.css';
interface ItemProps {
  id: number | string;
  name: string;
}

interface SelectDropdown {
  captionKey: string;
  className?: string;
  items: ItemProps[];
  handler: any;
  disable?: boolean;
  selected: string;
  title?: string;
  setAs?: string;
  name?: string;
}

const Dropdown = ({ captionKey, handler, className, items, selected, name, title }: SelectDropdown) => {
  return (
    <select value={selected} className={className} onChange={e => handler(name ? e : e.target.value)} defaultValue={selected} name={name}>
      {title ? (
        <option value='' className='optionList' disabled>
          {title}
        </option>
      ) : null}

      {items.map((item: any) => {
        return (
          <option className='optionList' key={item.id} value={item?.[captionKey]}>
            {item.name}
          </option>
        );
      })}
    </select>
  );
};

export default Dropdown;
