import ReactTooltip from 'react-tooltip';
import './details.css';
interface DetailsProps {
  heading: string;
  detail: string | number | null;
  styles?: string;
  tooltipId?: string;
}

const Details: React.FC<DetailsProps> = ({ heading, detail, styles, tooltipId }) => {
  return (
    <div className={`w-3/12 p-1 text-left ${styles || ''}  detailsInput`}>
      <p className='text-md headingText'>{heading}</p>
      <h5 className='text-lg mt-1 headingTitle' data-tip='hello world' data-for={tooltipId}>
        {detail}
      </h5>
      {tooltipId ? (
        <ReactTooltip type='dark' className='tooltipSection' id={tooltipId}>
          {detail}
        </ReactTooltip>
      ) : null}
    </div>
  );
};

export default Details;
