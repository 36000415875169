import React from 'react';

const FullNameCell: React.FC<{ value: string }> = ({ value }) => {
  return (
    <div className=' min-w-max'>
      <span>{value}</span>
    </div>
  );
};

export default FullNameCell;
