import CollapsibleContainer from '../../../components/Collapsible/CollapsibleContainer';
import Details from '../../../components/Details/details';
import { CARL_TYPES, TRANSFER_ACCOUNT_TYPES } from '../../../Constant/IRAPanelData';
import { UserDetailsProps } from '../../../Constant/Types/types';

interface AccountInfoProps {
  userDetails: UserDetailsProps;
}

const AccountInfo = ({ userDetails }: AccountInfoProps) => {
  return (
    <CollapsibleContainer title='ACCOUNT INFO'>
      <div className='mt-6 px-6'>
        <div className=' grid grid-cols-3'>
          <Details heading='Investor type' detail={CARL_TYPES[userDetails.accountinfo.ira_type ? 1 : 0]} tooltipId='Investor type' />
          <Details heading='Initial Account contribution' detail={`$ ${userDetails.accountinfo.initial_ira_amount}`} />
          <Details
            heading='Type of account'
            detail={TRANSFER_ACCOUNT_TYPES[parseInt(userDetails.accountinfo.ira_account_type) - 1]}
            tooltipId='TypeOfAccount'
          />
        </div>
      </div>
      {userDetails?.accountinfo.ira_account_type === '1' && (
        <div className='mt-6 px-6'>
          <div className=' grid grid-cols-3'>
            <Details heading='Custodian Account Name' detail={userDetails?.accountinfo.custodian_account_name || 'none'} />
            <Details heading='Custodian Account Number' detail={userDetails?.accountinfo.custodian_account_number || 'none'} />
            <Details heading='Amount' detail={`$ ${userDetails?.accountinfo.custodian_amount}` || 'none'} />
          </div>
        </div>
      )}
    </CollapsibleContainer>
  );
};

export default AccountInfo;
